import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { GetTitleRequest, DeleteTitleRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import ApiConnection from '../../utils/ApiConnection'


const Student_Rewards = () => {

    const [coinearned, setcoinearned] = useState("");
    const [coinasoftoday, setcoinasoftoday] = useState("");
    const [moneyasoftoday, setmoneyasoftoday] = useState("");
    const [coinredeemed, setcoinredeemed] = useState("");
    const [remainingcoin, setremainingcoin] = useState("");
    const [remainingmoneyvalue, setremainingmoneyvalue] = useState("");
    const [pagescompleted, setPagesCompleted] = useState(0); // Define pagescompleted
    const [rewardList, setRewardList] = useState([]); // Define rewardList


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetTitleResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    const { id } = useParams()
    // Define date and pros variables
    const date = new Date().toISOString().split('T')[0]; // Example: current date in YYYY-MM-DD format
    const now = new Date();
    const pros = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`; // Format: hh:mm
    
    useEffect(() => {
        GetDigitalRewadrd()
    }, [])

    const AddDigitalReward = async () => {

        let obj = {
            "total_pages_completed": pagescompleted,
            "coin_earned_is_session": coinearned,
            "total_coin_as_today": coinasoftoday,
            "total_money_as_today": moneyasoftoday,
            "coin_redeemed_in_session": coinredeemed,
            "remaining_coin": remainingcoin,
            "remaining_money_value": remainingmoneyvalue,

        }

        try {
            const response = await ApiConnection.post(`student-reward-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
            }
        } catch (e) {
            console.error(e);
         }
    }

    const GetDigitalRewadrd = async () => {

        try {
            const response = await ApiConnection.get(`student-reward-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`)
            if (response?.status == 200) {
                setRewardList(response?.data?.results);
                setcoinearned(response.data.results[0].coin_earned_is_session);
                setmoneyasoftoday(response.data.results[0].total_money_as_today);
                setcoinasoftoday(response.data.results[0].total_coin_as_today);
                setcoinredeemed(response.data.results[0].coin_redeemed_in_session);
                setremainingcoin(response.data.results[0].remaining_coin);
                setremainingmoneyvalue(response.data.results[0].remaining_money_value);            }

        } catch (e) {
            console.error(e);
         }
    };


    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Digital Rewards System</h5>
            <div className='form-group'>
                <hr />
            </div>

            <div className='row form-style mb-4 d-flex'>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Add Coins </label>
                            </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={coinearned}
                                onChange={(e) => { setcoinearned(e.target.value) }} placeholder='Coin Earned in Session'/>
                            </div>
                            {/* <div className='col-lg-3'>
            <i className="fa-solid fa-pen-to-square"></i>
            </div> */}
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Coins Redeemed in Session</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={coinredeemed}
                                onChange={(e) => { setcoinredeemed(e.target.value) }} placeholder='Coins Redeemed in Session'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Total Coins as of Today</label>                                                        </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={coinasoftoday}
                                onChange={(e) => { setcoinasoftoday(e.target.value) }} placeholder='Total Coins as of Today'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Remaining Coins</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={remainingcoin}
                                onChange={(e) => { setremainingcoin(e.target.value) }} placeholder='Remaining Coins'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Total Money as of Today</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={moneyasoftoday}
                                onChange={(e) => { setmoneyasoftoday(e.target.value) }} placeholder='Total Money as of Today'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 '>
                    <div className='form-group'>
                        <div className='row align-items-left'>
                            <div className='col-lg-6'>
                                <label className='d-block text-right mt-1'>Remaining Money Value</label>
                            </div>
                            <div className='col-lg-6'>
                                <input type='number' className='form-control' value={remainingmoneyvalue}
                                onChange={(e) => { setremainingmoneyvalue(e.target.value) }} placeholder='Remaining Money as of Today'/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <button className='formBtn float-right' style={{marginTop: '-30px'}} onClick={AddDigitalReward}>Add</button>
        </div>

    )
}

export default Student_Rewards