import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { AddExistingRequest, GetGroupRequest, GetSubjectChangeRequest, GetSubjectRequest, DeleteQuestionRequest, DownloadQuestionRequest, FetchtestRequest, GetExistingQuestionRequest, GetExistingTestRequest, GetGradeRequest, GetquesRequest, UpdateorderListRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import ApiConnection from '../../utils/ApiConnection'

const list = [
    {
        id: 'one',
        question: "Question one",
    },
    {
        id: 'two',
        question: "Question two",
    },
    {
        id: 'three',
        question: "Question three",
    },
]

const AssessmentDetails = () => {
    const [qList, setqList] = useState([])
    const [show, setShow] = useState(false);
    const [TestId, setTestId] = useState("");
    const [QuestionId, setQuestionId] = useState([])
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [subjectList, setsubjectList] = useState([]);
    const [testList, settestList] = useState([]);
    const [gradeList, setgradeList] = useState([]);
    const [subject, setsubject] = useState("")
    // const [GradeList, setGradeList] = useState([]);
    // const [GroupList, setGroupList] = useState([]);
    // const [ExistingQuestion, setExistingQuestion] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]); // State for selected question IDs
    const [group, setgroup] = useState("")


    console.log('gradeList', gradeList)
    
    const handleClose = () => {
        setShow(false);

        // if (!selectedSubject) {
        //     setSelectedQuestions([]);
        // }

        // setTestId("");
        // setSelectedGrade(null);
        // setSelectedSubject(null);
        // setSelectedQuestions([]);
    };

    const { id, pros } = useParams()
    const location = useLocation()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var viewdata = fetchacompanyReducer?.GettestResponse?.results[0]
    var NewOrder = fetchacompanyReducer?.UpdateorderListResponse?.results
    var ExistingQuestion = fetchacompanyReducer?.GetExistingResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetExistingTestResponse?.results
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectChangeResponse?.result
    var GroupList = fetchacompanyReducer?.GetGroupResponse?.results

    console.log('SubjectList', SubjectList)
    const as_id = id


    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchtestRequest(obj))

    }, [dispatch, id])

    useEffect(() => {
        dispatch(GetGradeRequest())
    }, [])

    useEffect(() => {
        if (selectedGrade) {
            // Fetch groups based on selected grade
            dispatch(GetGroupRequest({ grade_id: selectedGrade }));
        }
    }, [selectedGrade, dispatch]);

    useEffect(() => {
        if (selectedGroup) {
            // Fetch subjects based on selected group
            dispatch(GetSubjectRequest({ group_id: selectedGroup }));
        }
    }, [selectedGroup, dispatch]);

    const SubjectHandle = (e) => {
        setgroup(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSubjectChangeRequest(obj))
    }

    const handleGradeChange = async (gradeId) => {
        setSelectedGrade(gradeId);
        setSelectedGroup(null); // Reset group when grade changes
        setSelectedSubject(null); // Reset subject when grade changes
        setSubjects([]); // Clear subjects
        settestList([]);
        setSelectedQuestions([])

        try {
            const response = await ApiConnection.get(`group-crud/?server_type=production&grade_id=${gradeId}`)
            if (response?.status == 200) {
              setgradeList(response?.data?.results)
      
            }
          } catch (e) { }

    };

    const handleSubjectChange = async (subjectId) => {
        setSelectedSubject(subjectId);

        try {
            const response = await ApiConnection.get(`test-list-without-existing-id/?server_type=production&test_id=${id}&subject_id=${subjectId}`)
            if (response?.status == 200) {
              settestList(response?.data?.results)
      
            }
          } catch (e) { }
    };

    const handleGroupChange = async (groupId) => {
        setSelectedGroup(groupId);
        setSelectedSubject(null); // Reset subject when group changes
        settestList([]);
        setSelectedQuestions([]);
        // dispatch(GetSubjectChangeRequest({ group_id: groupId }))
        try {
            const response = await ApiConnection.get(`subjects-crud/?server_type=production&group_id=${groupId}`)
            if (response?.status == 200) {
              setsubjectList(response?.data?.results)
      
            }
          } catch (e) { }

    };

    const handleFilterQuestions = () => {
        return fetchacompanyReducer?.GetExistingResponse?.results.filter(question => {
            const matchesGrade = selectedGrade ? question.grade_id === selectedGrade : true;
            const matchesSubject = selectedSubject ? question.subject_id === selectedSubject : true;
            return matchesGrade && matchesSubject;
        });
    };

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [])

    useEffect(() => {
        var testdata = fetchacompanyReducer?.GetquesResponse?.results
        console.log("acctual", testdata)
        setqList(testdata)
    }, [fetchacompanyReducer?.GetquesResponse])



    const HandleonDrag = (result) => {
        const items = Array.from(qList)
        const [saveItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, saveItem)

        setqList(items)

        dispatch(UpdateorderListRequest({ obj: { "results": items }, test_id: id }))

        console.log("achanges", items)
    }


    useEffect(() => {

        setqList(NewOrder)

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.UpdateorderListResponse])


    const DownloadHandle = () => {
        dispatch(DownloadQuestionRequest(id))
    }


    useEffect(() => {

        setqList(NewOrder)

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.DeleteQuestionResponse])

    useEffect(() => {

        setShow(false)
        setqList(NewOrder)

        setTestId(""); // Reset TestId
        setgradeList([]);
        settestList([]);
        setsubjectList([]);
        setgroup([]);
        settestList([]);

        setSelectedGrade(null); // Reset selected grade
        setSelectedGroup(null); // Reset selected grade
        setSelectedSubject(null); // Reset selected subject
        setSelectedQuestions([]); // Clear selected questions

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.AddExistingResponse])

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteQuestionRequest({ id: id, test_id: as_id }))

                if (fetchacompanyReducer?.DeleteQuestionResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }

    const handleShow = () => {
        // setaddrowId(tid)
        setShow(true)
        dispatch(GetExistingTestRequest(id))

    };

    const handleCheckboxChange = (questionId) => {
        setSelectedQuestions((prevSelected) => {
            if (prevSelected.includes(questionId)) {
                // If already selected, remove it
                return prevSelected.filter((id) => id !== questionId);
            } else {
                // If not selected, add it
                return [...prevSelected, questionId];
            }
        });
    };

    const TestHandle = (value) => {
        setTestId(value)
        dispatch(GetExistingQuestionRequest(value))
    }

    const MultipleQuestionHandle = (event, row) => {


        var updatedList = [...QuestionId];
        if (event.target.checked) {
            updatedList = [...QuestionId, event.target.value];
        } else {
            updatedList.splice(QuestionId.indexOf(event.target.value), 1);
        }
        setQuestionId(updatedList);

    }


    const AddExistingQuestion = () => {
        if (TestId == '') {
            Swal.fire({
                icon: "error",
                text: 'Test should be must',
            });
        }
        else if (selectedQuestions.length == '') {
            Swal.fire({
                icon: "error",
                text: 'Question should be must',
            });
        }
        else {
            let obj = {
                "ex_test_id": TestId,
                "question_id": selectedQuestions
            }
            dispatch(AddExistingRequest({ obj: obj, test_id: id }))
        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>

                <div className='view-page'>
                    <div className='form-group d-flex align-items-center justify-content-between'>
                        <h6>Assessment information</h6>
                        {/* <button className='formBtn1' onClick={() => navigate('/Test/Viewschedule')}><i className="fa-solid fa-angle-left"></i> Back</button> */}
                        <NavLink to={`/${userinfo?.company_slug}/Test/Viewassessment`} className='formBtn1'>
                            <i className="fa-solid fa-angle-left"></i> Back
                        </NavLink>
                    </div>
                    <div className='form-group'>
                        <hr />
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Assessment Name:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Assessment Code:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.code}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Grade:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.grade_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Subject:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.subject_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Syllabus:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.syllabus_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Group:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.group_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Question Count:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{moment(viewdata?.date_of_birth).format('DD-MM-YYYY')}</b>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Question Count:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.total_question}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Fullmarks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.fullmarks}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Passmarks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.passmarks}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Time:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.time}</b>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Level:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.level}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Type:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.test_type}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Status:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.status}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Description:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.description}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <a id="DownloadQuestion" target='_blank'></a>

                <div className='innerlayout p-3'>

                    <div className='form-group text-right d-flex justify-content-end'>
                        <button onClick={DownloadHandle} className='formBtn1 mr-2' style={{ display: 'inline-block', background: '#dd1e88' }}>Download Question</button>
                        <button className='formBtn1 mr-2' onClick={handleShow}>Create Existing Question</button>
                        <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Test/Addtest/${id}`} style={{ display: 'inline-block' }}> Create New Question</NavLink>
                    </div>
                    {qList && qList.length > 0 ?

                        <>
                            <ul className='q-table-head'>
                                <li>
                                    <h2>Question No</h2>
                                    <h2>Question Name</h2>
                                    <h2>Grade Name</h2>
                                    <h2>Action</h2>
                                </li>
                            </ul>
                            <DragDropContext onDragEnd={HandleonDrag}>
                                <Droppable droppableId='characters'>
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef} className='q-table-body'>
                                            {qList && qList.map(({ id, grade_name, question, display_order }, index) => {
                                                return (
                                                    <Draggable key={id} draggableId={(display_order).toString()} index={index}>
                                                        {(provided) => (
                                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <h3><span><i className="fa-solid fa-arrows-up-down-left-right"></i></span> {display_order}</h3>
                                                                <h3><div
                                                                    dangerouslySetInnerHTML={{ __html: question }}
                                                                /></h3>
                                                                <h3>{grade_name}</h3>
                                                                <h3>
                                                                    {/* <NavLink to={`/${userinfo?.company_slug}/assesment/view-question/${as_id}/${id}`}><i className="fa-solid fa-eye" style={{marginRight: '8px'}}></i></NavLink> */}
                                                                    <NavLink to={`/${userinfo?.company_slug}/Test/edit-question/${as_id}/${id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                                    <button className='table-btn' onClick={() => DeleteHandle(id)}><i className="fa-solid fa-trash"></i></button>
                                                                </h3>
                                                            </li>
                                                        )}

                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>


                        :
                        <h4>No Question </h4>

                    }

                    {/* <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Question Number</th>
                                    <th>Grade</th>
                                    <th>Subject</th>
                                    <th>Module</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testdata && testdata.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.question_number}</td>
                                            <td>{item?.grade_name}</td>
                                            <td>{item?.subject_name}</td>
                                            <td>{item?.module_name}</td>
                                            <td>

                                                <NavLink to={`/${userinfo?.company_slug}/Test/Edittest/${item?.test_id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                <button><i className="fa-solid fa-trash"></i></button>

                                              
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> */}

                </div>
            </div>

            <Modal show={show} size="lg">
                <Modal.Header >
                    <Modal.Title>Existing Question</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
                                <select className='form-control' value={selectedGrade} onChange={(e) => handleGradeChange(e.target.value)}>
                                    <option>--select--</option>
                                    {GradeList?.map((list, index) => (
                                        <option key={index} value={list.grade_id}>{list.grade_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Group <span class="asterisk">*</span></label>
                            <select className='form-control' value={selectedGroup || ''}
                                onChange={(e) => handleGroupChange(e.target.value)}>
                                <option value="">--Group---</option>
                                {GroupList && GroupList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Subject <span class="asterisk">*</span></label>
                            <select className='form-control' value={selectedSubject || ''} onChange={(e) => handleSubjectChange(e.target.value)}
                                >
                                <option value="">-- Select Subject --</option>
                                {subjectList && subjectList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.subject_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>
                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Test</label>
                                < select className='form-control' value={TestId} onChange={(e) => setTestId(e.target.value)}>
                                    <option>--select--</option>
                                    {fetchacompanyReducer.GetExistingTestResponse?.results.map((list, index) => (
                                        <option key={index} value={list.id}>{list.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Test <span class="asterisk">*</span></label>
                                <select className='form-control' value={TestId} onChange={(e) => TestHandle(e.target.value)}>
                                    <option>--select--</option>
                                    {testList && testList
                                        .filter((list) => list.test_type !== "group")
                                        .map((list, index) => (
                                            <option key={index} value={list.id}>{list.name}</option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Question <span class="asterisk">*</span></label>
                                <Dropdown className='custom-dropdown-checkbox'>
                                    <Dropdown.Toggle variant="success">
                                        {selectedQuestions.length > 0 ? 'select ' + selectedQuestions.length : 'select Question'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul className='existingQuestion-list'>

                                            {ExistingQuestion && ExistingQuestion.map((list, i) => {
                                                return (
                                                    <li>
                                                        <input type="checkbox" id={`question-${i}`} value={list.id} checked={selectedQuestions.includes(list.id)} // Check if the question is selected
                                                            onChange={() => handleCheckboxChange(list.id)} />
                                                        <label htmlFor={`question-${i}`}>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: list?.question }}
                                                            />
                                                        </label>
                                                    </li>
                                                )
                                            })}


                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddExistingQuestion}>Add</button>

                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default AssessmentDetails