import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { FetchlocationRequest, GetlocationsRequest, GetStateRequest, GetcityRequest, FetchstudentRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import ApiConnection from '../../utils/ApiConnection'
import moment from "moment";
import Modal from 'react-bootstrap/Modal';

const Paymentlink = () => {

    const [Prospectfirstname, setProspectfirstname] = useState("")
    const [Prospectlastname, setProspectlastname] = useState("")
    const [ProspectDOB, setProspectDOB] = useState("")
    const [Prospectemail, setProspectemail] = useState("")
    const [Prospectphone, setProspectphone] = useState("")
    const [Prospectadd1, setProspectadd1] = useState("")
    const [Prospectadd2, setProspectadd2] = useState("")
    const [Prospectcountry, setProspectcountry] = useState("")
    const [Prospectstate, setProspectstate] = useState("")
    const [Prospectcity, setProspectcity] = useState("")
    const [Prospectpincode, setProspectpincode] = useState("")
    const [Billingfirstname, setBillingfirstname] = useState("")
    const [Billinglastname, setBillinglastname] = useState("")
    const [Billingemail, setBillingemail] = useState("")
    const [Billingphone, setBillingphone] = useState("")
    const [Billingadd1, setBillingadd1] = useState("")
    const [Billingadd2, setBillingadd2] = useState("")
    const [Billingcountry, setBillingcountry] = useState("")
    const [Billingcity, setBillingcity] = useState("")
    const [Billingstate, setBillingstate] = useState("")
    const [Billingpincode, setBillingpincode] = useState("")
    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [gender, setgender] = useState("")
    const [dob, setdob] = useState("")
    const [studentemail, setstudentemail] = useState("")
    const [grade, setgrade] = useState("")
    const [schoolyear, setschoolyear] = useState("")
    const [schoolyear1, setschoolyear1] = useState("")
    const [school, setschool] = useState("")
    const [paymentlink, setpaymentlink] = useState("")
    const [BillingList, setBillingList] = useState([])
    const [GuidelinesList, setGuidelinesList] = useState([])
    const [show3, setShow3] = useState(false);
    const [isChecked, setIsChecked] = useState(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id, pros, sid } = useParams()
    console.log(id, pros, sid)

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo']
    };

    useEffect(() => {

        var editdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
        console.log(fetchacompanyReducer?.FetchstudentResponse)
        setfirstname(editdata?.first_name)
        setlastname(editdata?.last_name)
        setgender(editdata?.gender)
        setgrade(editdata?.grade_id)
        setschool(editdata?.school_id)
        // setteacher(editdata?.school_teacher_id)
        setschoolyear(editdata?.school_year)
        setdob(moment(editdata?.date_of_birth).format('YYYY-MM-DD'))
        setstudentemail(editdata?.email)

        setProspectfirstname(editdata?.prospect_first_name)
        setProspectlastname(editdata?.prospect_last_name)
        setProspectemail(editdata?.prospect_email)
        setProspectphone(editdata?.prospect_mobile_phone)
        setProspectadd1(editdata?.prospect_street_address_1)
        setProspectadd2(editdata?.prospect_street_address_2)
        setProspectcountry(editdata?.prospect_country_id)
        setProspectcity(editdata?.prospect_city_id)
        setProspectstate(editdata?.prospect_state_id)
        setProspectpincode(editdata?.prospect_zipcode)
        

        let obj = {
            id: editdata?.prospect_country_id,
        };
        dispatch(GetStateRequest(obj));

        let obj1 = {
            id: editdata?.prospect_state_id,
        };
        dispatch(GetcityRequest(obj1));

        setBillingfirstname(editdata?.prospect_first_name)
        setBillinglastname(editdata?.prospect_last_name)
        setBillingemail(editdata?.prospect_email)
        setBillingphone(editdata?.prospect_mobile_phone)
        setBillingadd1(editdata?.prospect_street_address_1)
        setBillingadd2(editdata?.prospect_street_address_2)
        setBillingcountry(editdata?.prospect_country_id)
        setBillingstate(editdata?.prospect_state_id)
        setBillingcity(editdata?.prospect_city_id)
        setBillingpincode(editdata?.prospect_zipcode)

    }, [fetchacompanyReducer?.FetchstudentResponse])

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const GetstudentRequest = () => {
        // setaddrowId(tid)
        let obj = {
            id: sid
        }
        dispatch(FetchstudentRequest(obj))
    };

    const GetBillingDetails = async (e) => {

        try {
            const response = await ApiConnection.get(`membership-payment-checkout/?server_type=production&id=${id}&student_id=${sid}&company_id=${pros}`)
            if (response?.status == 200) {
                setBillingList(response?.data?.results[0])
                console.log(response?.data?.results[0])
            }
        } catch (e) { }

    }

    const SubmitHandle = async (id) => {

            
    }
    

    useEffect(() => {
        GetstudentRequest();
        GetBillingDetails();
    }, [])

    const handleClose3 = () => {
        setShow3(false);
        // dispatch(GetSchoolRequest())
    }

    const handleShow3 = async (event) => {
            setShow3(true);
            try {
                const response = await ApiConnection.get(`student-crud/?server_type=production&id=${sid}`)
                if (response?.status == 200) {
                    setGuidelinesList(response?.data?.results[0])
                    console.log(response?.data?.results[0])
                }
            } catch (e) { }    };

    const SubmitTandC = async () => {

        if (!isChecked) {
            Swal.fire({
                icon: "error",
                text: 'Please accept the terms and conditions.',
            });
            return; // Exit the function if the checkbox is not checked
        }
       
        let obj = {
            // "location_id": location1,
            // "total_coin_money_factor": moneyfactor,
        }

        try {
            const response = await ApiConnection.post(`membership-setup-cruds/?server_type=production`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });  
            }
        } catch (e) { }
}

const handleShareLink = () => {
    // Navigate to the payment link
    window.open(BillingList?.paymnet_link, '_blank'); // Open in a new tab
};

const handleCancel = () => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to cancel payment?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '    Yes    ',
        cancelButtonText: '    No    '
    }).then((result) => {
        if (result.isConfirmed) {
            navigate(-1); // Navigate back to the previous page
        }
    });
};


    return (
        <div className='common-layout ml-5'>
            <div className='innerlayout p-3'>

                <div className='row form-style billing'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Name :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{BillingList?.billing_first_name}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Service Name :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{BillingList?.service_name}</b>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Billing Address :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{BillingList?.billing_address_1}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Recurring :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{BillingList?.recurring} ({BillingList?.monthly_recurring_fee})</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Today's Payment :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{BillingList?.todays_total_fee}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        {/* <label className='selectlabel'>Total (Fututre Recurring Months)</label>
                                <input type='text' disabled className='form-control' 
                                   /> */}
                        </div>
                    </div>

                    <div className='col-lg-12 mt-4'>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '5px' }}>
        <a href={BillingList?.paymnet_link} target="_blank" className='formBtn1 paybtn' style={{ backgroundColor: '#d53d00', marginRight: '10px' }}>Pay Now</a>
        
        <button className='formBtn1 linkbtn' onClick={() => handleShow3()} style={{ marginRight: '10px' }}>Share Link</button>
        
        <button className='formBtn1 linkbtn' style={{ backgroundColor: 'gray' }} onClick={() => handleCancel()}>Cancel</button>
    </div>
</div>

                </div>
            </div>

            <Modal show={show3} size="lg">
<Modal.Header >
    <Modal.Title>Offline Payment</Modal.Title>
    <button onClick={handleClose3} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
</Modal.Header>
<Modal.Body style={{ maxHeight: '375px', overflowY: 'auto' }}>


<div className='row form-style mb-4'>
    <label className='selectlabel ml-3' style={{ fontSize: '16px', marginBottom: '12px', fontWeight: '500' }}>Terms and Conditions</label>

    <div className='col-lg-12'>
        <div className='form-group' style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <label className='selectlabel' style={{ fontSize: '15px', marginBottom: '10px' }}>Learning Center Guidelines</label>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: GuidelinesList?.learning_center_guidelines }} />
            <p style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }}>{GuidelinesList?.learning_center_guidelines_notes}</p>
        </div>
    </div>
    <div className='col-lg-12'>
        <div className='form-group' style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <label className='selectlabel' style={{ fontSize: '15px', marginBottom: '10px' }}>Online Learning Guidelines</label>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: GuidelinesList?.online_learning_guidelines }} />
            <p style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }}>{GuidelinesList?.online_learning_guidelines_notes}</p>
        </div>
    </div>
    <div className='col-lg-12'>
        <div className='form-group' style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <label className='selectlabel' style={{ fontSize: '15px', marginBottom: '10px' }}>Media Release Form</label>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: GuidelinesList?.media_release_form }} />
            <p style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }}>{GuidelinesList?.media_release_form_notes}</p>
        </div>
    </div>
    <div className='col-lg-12'>
        <div className='form-group' style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <label className='selectlabel' style={{ fontSize: '15px', marginBottom: '10px' }}>Teacher Consent Form</label>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: GuidelinesList?.teacher_consent_form }} />
            <p style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }}>{GuidelinesList?.teacher_consent_form_notes}</p>
        </div>
    </div>
    <div className='col-lg-12'>
        <div className='form-group' style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
            <label className='selectlabel' style={{ fontSize: '15px', marginBottom: '10px' }}>Leave Unescorted Consent Form</label>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: GuidelinesList?.leave_unescorted_consent_form }} />
            <p style={{ border: '1 px solid #ddd', padding: '10px', borderRadius: '5px', fontSize: '12px', lineHeight: '1.2' }}>{GuidelinesList?.leave_unescorted_consent_form_notes}</p>
        </div>
    </div>
</div>


</Modal.Body>
<Modal.Footer className='ml-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '500' }}>
    {/* <button className='formBtn' disabled onClick={SubmitTandC}>OK</button> */}
    <div className='form-check'>
                    <input 
                        type='checkbox' 
                        className='form-check-input' 
                        id='termsCheckbox' 
                        checked={isChecked} 
                        onChange={handleCheckboxChange} 
                    />
                    <label className='form-check-label float-right' htmlFor='termsCheckbox'>
                        I accept the Terms and Conditions <span class="asterisk">*</span>
                    </label>
                </div>
                <button 
                    className='formBtn mr-2' 
                    onClick={SubmitTandC}
                >
                    Confirm
                </button>
</Modal.Footer>
</Modal>

        </div>

        
    )

    
}



export default Paymentlink