import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { ViewprospectsRequest, GetGuardianRequest, MakePrimaryRequest, DeletestudentRequest, GetStudentListRequest, GetProspectiveRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Switch from "react-switch";
import DataTable from 'react-data-table-component';
import ApiConnection from '../../utils/ApiConnection'
import EmployeeActivities from '../employee/EmployeeActivities'
import ProspectActivities from './ProspectActivities'
import ProspectDocuments from './ProspectDocuments'
import ActivitiesReport from './ActivitiesReport'

const ViewProspects = () => {
     const [key, setKey] = useState('guardian');
     const [Namesearch, setNamesearch] = useState("")
     const [FilterResult, setFilterResult] = useState([])
     const [studentList, setstudentList] = useState([])
     const [ActivitiesList, setActivitiesList] = useState([])
     const [DocumentList, setDocumentList] = useState([])
     const [checked, setchecked] = useState(false)
     const [prosId, setprosId] = useState(null);
     const [usrId, setusrId] = useState(null);
     const dispatch = useDispatch()
     const { id, pros } = useParams()
     let navigate = useNavigate()

     const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
     var userinfo = JSON.parse(localStorage.getItem('userinfo'))

     var viewdata = fetchacompanyReducer?.ViewprospectsResponse?.results[0]
     var viewleadsdata = fetchacompanyReducer?.ViewprospectsResponse?.lead_source_result
     var guardianList = fetchacompanyReducer?.GetGuardianResponse?.results
     console.log(id, pros)
     console.log(fetchacompanyReducer.GetstudentResponse)


     // useEffect(() => {
     //      let obj1 = {
     //           prospect_id: id
     //      }

     //      dispatch(GetStudentListRequest(obj1))
     // }, [])

     useEffect(() => {
          GetStudentList()
          GetDocumentsList()
          GetActivitiesList()
     }, [])

     const GetDocumentsList = async () => {

          try {
               const response = await ApiConnection.get(`student-crud/?server_type=production&prospect_id=${id}`)
               if (response?.status == 200) {
                    console.log("get Documents", response.data)
                    setDocumentList(response?.data?.results)
               }
          } catch (e) { }
     }

     const GetActivitiesList = async () => {

          try {
               const response = await ApiConnection.get(`activities_crud/?server_type=production&prospect_id=${id}`)
               if (response?.status == 200) {
                    console.log("get Activities", response.data)
                    setActivitiesList(response?.data?.results)
               }
          } catch (e) { }
     }

     const GetStudentList = async () => {

          try {
               const response = await ApiConnection.get(`student-crud/?server_type=production&prospect_id=${id}`)
               if (response?.status == 200) {
                    console.log("get Student", response.data)
                    setstudentList(response?.data?.results)
               }
          } catch (e) { }
     }

     useEffect(() => {
          let obj1 = {
               prospect_id: id
          }
          GetStudentList()
     }, [fetchacompanyReducer?.DeletestudentResponse])

     const DeleteHandle = (id) => {
          Swal.fire({
               title: 'Are you sure?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
               if (result.isConfirmed) {
                    dispatch(DeletestudentRequest(id))

                    if (fetchacompanyReducer?.DeletestudentResponse) {
                         Swal.fire(
                              'Deleted!',
                              'Your file has been deleted.',
                              'success'
                         )
                    }

               }
          })
     }

     useEffect(() => {

          let obj = {
               id: id,
               page_size: "10",
               page: "1",
               location_id: ""
          }

          let obj1 = {
               prospect_id: id
          }

          dispatch(ViewprospectsRequest(obj))
          dispatch(GetGuardianRequest(obj))
          // dispatch(GetStudentListRequest(obj1))

     }, [])

     useEffect(() => {

          let obj = {
               id: id,
               page_size: "10",
               page: "1",
               location_id: ""
          }
          dispatch(GetGuardianRequest(obj))

     }, [fetchacompanyReducer?.MakePrimaryResponse])

     const PrimaryhandleChange = (id) => {
          setchecked(true);
     }

     //     const ViewGuardian = (gid) =>{
     //      navigate(`/${userinfo?.company_slug}/guardians/view-guardians/${gid}`, { state: { prosId: id } })
     //     }


     const MakePrimary = (id, userid) => {
          console.log("MakePrimary", userid)
          Swal.fire({
               title: 'Are you sure make primary?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Yes, make primary!'
          }).then((result) => {
               if (result.isConfirmed) {
                    let obj = {
                         is_prospect: 1
                    }
                    dispatch(MakePrimaryRequest({ obj: obj, id: id, userid: userid, navigate: navigate }))

                    if (fetchacompanyReducer?.MakePrimaryResponse) {
                         Swal.fire(
                              'set primary!',
                              'Your file has been set.',
                              'success'
                         )
                    }

               }
          })
     }

     useEffect(() => {
          let obj = {
               id: id,
          }

          dispatch(ViewprospectsRequest(obj))
     }, [fetchacompanyReducer?.MakePrimaryResponse])


     return (
          <div className='common-layout'>
               <Loader visible={fetchacompanyReducer.isloading} />
               <div className='innerlayout p-3'>
                    <div className='mb-4'>
                         <h3> View Prospects</h3>
                    </div>

                    <div className='view-page'>
                         <div className='form-group'>
                              <h6>Prospect information</h6>
                         </div>
                         <div className='form-group'>
                              <hr />
                         </div>
                         <div className='row'>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Salutation:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.salutation}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Name:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.first_name} {viewdata?.last_name}</b>
                                                  <NavLink className="ml-2" to={`/${userinfo?.company_slug}/prospects/edit/${id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Email:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.email}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Mobile:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.mobile_phone}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Other Phone:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.other_phone}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Prospect rating:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.propect_rating}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Prospect status:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.propect_status}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-12'>
                                   <div className='form-group'>
                                        <h6>Address information</h6>
                                        <hr />
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Country:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.country_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>State:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.state_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>City:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.city_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Address 1:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.street_address_1}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Address 2:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.street_address_2}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Zipcode:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.zipcode}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Description:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.description}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Customer Description:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.customer_description}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='col-lg-12'>
                              <div className='form-group'>
                                   <h6>Lead information</h6>
                                   <hr />
                              </div>
                         </div>


                         {viewleadsdata && viewleadsdata.map((source, i) => {
                              return (
                                   <div className='form-group' key={i}>
                                        <label className='d-block'>{source?.lead_interest_name} </label>
                                        <b>{source?.lead_source_name}</b>
                                   </div>
                              )
                         })}

                         <div className='prospects-tabs mb-4'>
                              <Tabs
                                   id="controlled-tab-example"
                                   activeKey={key}
                                   onSelect={(k) => setKey(k)}
                                   className="mb-3"
                              >
                                   <Tab eventKey="guardian" title="Guardians">
                                        <div className='module_list'>
                                             <div class="module_list-top"><h5>Guardians</h5><NavLink className='formBtn1' to={`/${userinfo?.company_slug}/prospects/add-guardians/${id}/${pros}`}><i className="fa-solid fa-plus"></i> Add Guardians</NavLink></div>

                                             {guardianList && guardianList.length > 0 ?
                                                  <div className='table-responsive'>
                                                       <table className='custom-table'>
                                                            <thead>
                                                                 <tr>
                                                                      <th>Full Name</th>
                                                                      <th>Relation with</th>
                                                                      <th>Mobile no</th>
                                                                      <th>Email</th>
                                                                      <th>Primary Guardian</th>
                                                                      <th>Make Primary</th>
                                                                      <th>Action</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody>
                                                                 {guardianList && guardianList.map((item, index) => {
                                                                      return (
                                                                           <tr key={index}>
                                                                                <td>{item?.first_name} {item?.last_name}</td>
                                                                                <td>{item?.relation}</td>
                                                                                <td>{item?.mobile_phone}</td>
                                                                                <td>{item?.email}</td>
                                                                                <td>{item?.is_prospect == 0 ? <span class="badge badge-danger p-2">No</span> : <span class="badge badge-success p-2">Yes</span>} </td>
                                                                                <td>{item?.is_prospect == 0 ? <button className='btn-primary btn btn-sm p-2' style={{ background: '#0069d9', color: '#fff' }} onClick={() => MakePrimary(item?.id, item?.user_id)}>Make Primary</button> : 'Already Primary'}</td>
                                                                                <td>
                                                                                     {/* <button onClick={ViewGuardian(item?.id)}><i className="fa-solid fa-eye"></i></button>    */}
                                                                                     <NavLink to={`/${userinfo?.company_slug}/prospects/edit-guardians/${item?.id}`} className="mr-2"><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                                                     <NavLink to={`/${userinfo?.company_slug}/prospects/view-guardians/${id}/${item?.id}/`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                                                                                     <button><i className="fa-solid fa-trash"></i></button>
                                                                                </td>
                                                                           </tr>
                                                                      )
                                                                 })}



                                                            </tbody>

                                                       </table>
                                                  </div>
                                                  :
                                                  <h6>No data found</h6>
                                             }

                                        </div>
                                   </Tab>
                                   <Tab eventKey="student" title="Students">


                                        <div className='module_list'>
                                             <div class="module_list-top"><h5>Students</h5><NavLink className='formBtn1' to={`/${userinfo?.company_slug}/prospects/add-students/${id}/${pros}`}><i className="fa-solid fa-plus"></i> Add Student</NavLink></div>



                                             {studentList && studentList.length > 0 ?
                                                  <div className='table-responsive'>
                                                       <table className='custom-table'>
                                                            <thead>
                                                                 <tr>
                                                                      <th>Student Name</th>
                                                                      <th>Grade</th>
                                                                      <th>School</th>
                                                                      <th>School Year</th>
                                                                      <th>Prospect/Customer</th>
                                                                      <th>Enrollment Status</th>
                                                                      <th>Last Attendance</th>
                                                                      <th>Delivery</th>
                                                                      <th>Center</th>
                                                                      <th>Location Type</th>
                                                                      <th>Action</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody>
                                                                 {studentList && studentList.map((item, index) => {
                                                                      return (
                                                                           <tr key={index}>
                                                                                <td>{item?.first_name} {item?.last_name}</td>
                                                                                <td>{item?.grade_name}</td>
                                                                                <td>{item?.school_name}</td>
                                                                                <td>{item?.school_year}</td>
                                                                                <td>{item?.prospect_first_name} {item?.prospect_last_name}</td>
                                                                                <td>{item?.enrollment_status}</td>
                                                                                <td>{item?.last_attendance}</td>
                                                                                <td>{item?.delivery}</td>
                                                                                <td>{item?.center}</td>
                                                                                <td>{item?.virtual_loocation}</td>
                                                                                <td>
                                                                                     {/* <button onClick={ViewGuardian(item?.id)}><i className="fa-solid fa-eye"></i></button>    */}
                                                                                     <NavLink to={`/${userinfo?.company_slug}/prospects/edit-students/${item?.id}`} className="mr-2"><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                                                     <NavLink to={`/${userinfo?.company_slug}/prospects/view-students/${id}/${item?.id}/`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                                                                                     <button><i className="fa-solid fa-trash"></i></button>
                                                                                </td>
                                                                           </tr>
                                                                      )
                                                                 })}



                                                            </tbody>

                                                       </table>
                                                  </div>
                                                  :
                                                  <h6>No data found</h6>
                                             }
                                        </div>

                                   </Tab>
                                   <Tab eventKey="ProspectDocuments" title="Documents">
                                   {key === 'ProspectDocuments' && <ProspectDocuments id={id} />}                             
                                   </Tab>
                                   <Tab eventKey="ProspectActivities" title="Tasks">
                                   {key === 'ProspectActivities' && <ProspectActivities id={id} />}                             
                                   </Tab>
                                   <Tab eventKey="ActivitiesReport" title="Activities">
                                   {key === 'ActivitiesReport' && <ActivitiesReport id={id} />}                             
                                   </Tab>
                              </Tabs>
                         </div>




                    </div>

                    <div className='button-part mb-4'>
                         <ul>
                              <li>
                                   <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/prospects`}><i className="fa-solid fa-angle-left"></i> Back Prospects List</NavLink>
                              </li>

                         </ul>
                    </div>

               </div>
          </div>
     )
}

export default ViewProspects