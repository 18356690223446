import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetLocationRequest, ExportguardianRequest, DeleteguardianRequest, ViewguardianRequest, GetGuardianRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const GuardiansList = () => {

    const column = [
        {
          "key": "First Name",
          "value":"First_Name",
          "ischecked":false
        },
        {
          "key": "Last Name",
          "value":"Last_Name",
          "ischecked":false
        },
        {
          "key": "Created Date",
          "value":"Status",
          "ischecked":false
        },
        {
          "key": "Mobile No",
          "value":"Mobile_Phone",
          "ischecked":false
        },
        {
          "key": "Email",
          "value":"Email",
          "ischecked":false
        },
        {
            "key": "Billing Street",
            "value":"Street_Address_1",
            "ischecked":false
          },
          {
            "key": "Billing City",
            "value":"Description",
            "ischecked":false
          },
          {
            "key": "Guardians Status",
            "value":"Customer_Description",
            "ischecked":false
          },
        
        
      ]
      const [numberpagination, setnumberpagination] = useState([])
      const [active, setactive] = useState(0)
      const [location, setlocation] = useState("")
      const [name, setname] = useState("")
      const [status, setstatus] = useState("")
      const [show, setShow] = useState(false);
      const [columnName, setColumnName] = useState(column)
      const [activePage, setActivePage] = useState(1);
      const [itemsPerPage, setItemsPerPage] = useState(10);
      const [numberOfPages, setNumberOfPages] = useState(0);
      const [traininfo, settraininfo] = useState([{
        label:'',
        assigndate:null,
        duedate:null , 
        completedate:null , 
        isdisabled:false
      }]);
    
      const handleClose = () => setShow(false);
     
    
      const dispatch = useDispatch()
      const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
      var userinfo = JSON.parse(localStorage.getItem('userinfo'))
      var guardianList = fetchacompanyReducer?.GetGuardianResponse?.results
      var locationList = fetchacompanyReducer?.GetLocationResponse?.results
      let locationId = localStorage.getItem("locationId");
      const totalCount = fetchacompanyReducer?.GetGuardianResponse?.total_stats[0]?.total_count || 0;

    console.log("Fetching", fetchacompanyReducer)
     
      const handleShow = () => {
        setShow(true)
        setColumnName(column)
          const link = document.getElementById("Download");
          link.removeAttribute("href");
      };

        // Function to fetch employees based on current filters and pagination
  const fetchGuardian = (itemsPerPage, activePage) => {
    const payload = {
      "page_size": itemsPerPage,
      "page": activePage,
      "name": name,
      "status": status,
      "location_id": "",
      "id": ""
    };
    dispatch(GetGuardianRequest(payload));
  };
    
      useEffect(() => {
        fetchGuardian(itemsPerPage, activePage); // Fetch employees when the component mounts
        dispatch(GetLocationRequest());
      }, [activePage, itemsPerPage]);
    
      function isEmpty(item){
        if(item == '' || item == null ||item == undefined) return true; return false;
      }
        useEffect(()=>{
          if(!isEmpty(fetchacompanyReducer?.GetguardianResponse)){
            if(fetchacompanyReducer?.GetguardianResponse?.total_stats&&fetchacompanyReducer?.GetguardianResponse?.total_stats.length > 0)
            var totalLength = fetchacompanyReducer?.GetguardianResponse?.total_stats[0]?.total_count
      
            console.log(totalLength)
        
            var count = totalLength / 10
            var roundof = Math.ceil(count)
            let pagArr = []
        
            for(let i=0; i<roundof; i++){
              pagArr.push(i)
            }
            setnumberpagination(pagArr)
          }
        },[fetchacompanyReducer?.GetguardianResponse])
    
     
    
      const paginateHandle = (number) =>{
        setactive(number - 1)
        console.log(number)
        let obj= {
          "page_size":10,
          'page':number,
          'name':'',
          'status':'',
          'location_id':''
        }
    
        dispatch(ViewguardianRequest(obj))
      }

      const dateHandle = (date, key, index)=>{
        console.log(index)
        var newValue = [...traininfo];
        newValue[index][key] = date
        settraininfo(newValue);
    }
    
      const SearchHandle = ()=>{
        let obj= {
          "page_size":10,
          'page':1,
          'name':name,
          'status':status,
          'location_id':location
        }
    
        dispatch(ViewguardianRequest(obj))
      }
    
      const ResetHandle = ()=>{
        setname("")
        setstatus("")
        setlocation("")
        let obj= {
          "page_size":10,
          'page':1,
          'name':'',
          'status':'',
          'location_id':''
        }
    
        dispatch(ViewguardianRequest(obj))
      }
    
    
      const CheckboxHandle = (index, e)=>{
        const { value, checked } = e.target;
          let raw = [...columnName]
          if(checked){
            raw[index].ischecked = true
          } else {
            raw[index].ischecked = false
          }
          setColumnName(raw)
      }
    
      const ExportHandle = ()=>{
    
        let Arr = []
    
        columnName.forEach(element => {
    
          if(element.ischecked){
            Arr.push(element.value)
          }
        
        });
    
        let obj = {
          "column_selector":Arr
        }
    
        if(obj.column_selector.length > 1){
          dispatch(ExportguardianRequest(obj))
          setShow(false)
        } else {
          Swal.fire({
            icon: "error",
            text: 'Choose atleast two columns',
          });
        }
    
    
      }

      useEffect(()=>{
        let obj= {
          "page_size":10,
          'page':1,
          'name':'',
          'status':'',
          'location_id':''
        }
    
        dispatch(ViewguardianRequest(obj))
    },[fetchacompanyReducer?.DeleteguardianResponse])
    
    
        const DeleteHandle = (id)=>{
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(DeleteguardianRequest(id))
    
                    if(fetchacompanyReducer?.DeleteguardianResponse){
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                          )
                    }
                 
                }
              })
        }

        useEffect(() => {
          if (totalCount > 0) {
            setNumberOfPages(Math.ceil(totalCount / itemsPerPage)); // Calculate total pages based on total count
          }
        }, [totalCount, itemsPerPage]);
      
        const handleItemsPerPageChange = (e) => {
          const newItemsPerPage = parseInt(e.target.value, 10); // Get the new items per page value
          console.log(e.target.value, newItemsPerPage)
          setItemsPerPage(newItemsPerPage);
          console.log(itemsPerPage);
          setActivePage(1); // Reset to first page on items per page change
          fetchGuardian(newItemsPerPage, activePage); // Fetch employees with new items per page
        };
      
        const handleNextPage = () => {
          if (activePage < numberOfPages) {
            setActivePage(activePage + 1);
            fetchGuardian(itemsPerPage, activePage + 1); // Fetch employees for the new page
          }
        };
      
        const handlePreviousPage = () => {
          if (activePage > 1) {
            setActivePage(activePage - 1);
            fetchGuardian(itemsPerPage, activePage - 1); // Fetch employees for the new page
          }
        };
      
        const renderPagination = () => {
          const pages = [];
          if (numberOfPages <= 3) {
            for (let i = 1; i <= numberOfPages; i++) {
              pages.push(
                <li key={i}>
                  <button
                    className={activePage === i ? 'active' : ''}
                    onClick={() => {
                      setActivePage(i);
                      fetchGuardian(itemsPerPage, i);
                    }}
                  >
                    {i}
                  </button>
                </li>
              );
            }
          } else {
            if (activePage <= 2) {
              pages.push(
                <li key={1}>
                  <button
                    className={activePage === 1 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(1);
                      fetchGuardian(itemsPerPage, 1);
                    }}
                  >
                    1
                  </button>
                </li>
              );
              pages.push(
                <li key={2}>
                  <button
                    className={activePage === 2 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(2);
                      fetchGuardian(itemsPerPage, 2);
                    }}
                  >
                    2
                  </button>
                </li>
              );
              pages.push(
                <li key={3}>
                  <button
                    className={activePage === 3 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(3);
                      fetchGuardian(itemsPerPage, 3);
                    }}
                  >
                    3
                  </button>
                </li>
              );
            } else if (activePage >= numberOfPages - 1) {
              pages.push(
                <li key={numberOfPages - 2}>
                  <button
                    className={activePage === numberOfPages - 2 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(numberOfPages - 2);
                      fetchGuardian(itemsPerPage, numberOfPages - 2);
                    }}
                  >
                    {numberOfPages - 2}
                  </button>
                </li>
              );
              pages.push(
                <li key={numberOfPages - 1}>
                  <button
                    className={activePage === numberOfPages - 1 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(numberOfPages - 1);
                      fetchGuardian(itemsPerPage, numberOfPages - 1);
                    }}
                  >
                    {numberOfPages - 1}
                  </button>
                </li>
              );
              pages.push(
                <li key={numberOfPages}>
                  <button
                    className={activePage === numberOfPages ? 'active' : ''}
                    onClick={() => {
                      setActivePage(numberOfPages);
                      fetchGuardian(itemsPerPage, numberOfPages);
                    }}
                  >
                    {numberOfPages}
                  </button>
                </li>
              );
            } else {
              pages.push(
                <li key={1}>
                  <button
                    className={activePage === 1 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(1);
                      fetchGuardian(itemsPerPage, 1);
                    }}
                  >
                    1
                  </button>
                </li>
              );
              pages.push(
                <li key={activePage - 1}>
                  <button
                    className={activePage === activePage - 1 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(activePage - 1);
                      fetchGuardian(itemsPerPage, activePage - 1);
                    }}
                  >
                    {activePage - 1}
                  </button>
                </li>
              );
              pages.push(
                <li key={activePage}>
                  <button
                    className={activePage === activePage ? 'active' : ''}
                    onClick={() => {
                      setActivePage(activePage);
                      fetchGuardian(itemsPerPage, activePage);
                    }}
                  >
                    {activePage}
                  </button>
                </li>
              );
              pages.push(
                <li key={activePage + 1}>
                  <button
                    className={activePage === activePage + 1 ? 'active' : ''}
                    onClick={() => {
                      setActivePage(activePage + 1);
                      fetchGuardian(itemsPerPage, activePage + 1);
                    }}
                  >
                    {activePage + 1}
                  </button>
                </li>
              );
              pages.push(
                <li key={numberOfPages}>
                  <button
                    className={activePage === numberOfPages ? 'active' : ''}
                    onClick={() => {
                      setActivePage(numberOfPages);
                      fetchGuardian(itemsPerPage, numberOfPages);
                    }}
                  >
                    {numberOfPages}
                  </button>
                </li>
              );
            }
          }
          return pages;
        };


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> Guardians Management</h3>
        </div>
        <div className='row form-style mb-4 align-items-center'>
          <div className='col-lg-3'>
              <div className='form-group'>
                  <label>Guardians Name</label>
                  <input type='text' className='form-control' placeholder='Search..'
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                  />
              </div>
          </div>
         
          <div className='col-lg-3'>
         <div className='form-group'>
         <label>Location</label>
            <select className='form-control'  value={location}
             onChange={(e)=>setlocation(e.target.value)}>
              <option value="">--Location---</option>
              {locationList&&locationList.map((item, i)=>{
                  return (
                      <option key={i} value={item?.id}>{item?.location_name}</option>
                  )
              })}
            </select>
         </div>
     </div>
     <div className='col-lg-2'>
              <div className='form-group'>
                  <label>Guardians Status</label>
                 <select className='form-control' value={status}
                  onChange={(e)=>setstatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  
                 </select>
              </div>
          </div>
          <div className='col-lg-2'>
              <div className='form-group'>
                  <label>Enrollment Status</label>
                 <select className='form-control' value={status}
                  onChange={(e)=>setstatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  
                 </select>
              </div>
          </div>
          <div className='col-lg-3'>
                            <div className='form-group'>
                            <label>Start</label>
                                <DatePicker onChange={(date)=>dateHandle(date, 'duedate')} className='form-control'
                                placeholderText="MM/DD/YYYY"
                                />
                                </div>

                            </div> 
                            <div className='col-lg-3'>
                            <div className='form-group'>
                            <label>End</label>
                                <DatePicker onChange={(date)=>dateHandle(date, 'duedate')} className='form-control'
                                placeholderText="MM/DD/YYYY"
                                />
                                </div>

                            </div> 

          <div className='col-lg-4'>
              <div className='form-group'>
                  <label className='d-block'>&nbsp;</label>
                  <div className='d-flex'>
                  <button className='btn btn-primary mr-3' onClick={SearchHandle}>Search</button>
                  <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
              </div>
              </div>
          </div>
      </div>
      <div className='button-part mb-4'>
          <ul>
            
            <li>
                <button className='formBtn1'  onClick={handleShow}><i className="fa-solid fa-file-excel"></i> Export </button>
            </li>
          </ul>
      </div>
      <a id="Download" download></a>
    {guardianList&&guardianList.length > 0  ?
      <div className='table-responsive'>
          <table className='custom-table'>
            <thead>
              <tr>
              <th>Name</th>
              <th>Prospects name</th>
              <th>Mobile no</th>
              <th>Email</th>
              <th>Address</th>
              <th>Pincode</th>
              <th>Location</th>
              <th>Action</th>
              </tr>
            </thead>
             <tbody>
             {guardianList&&guardianList.map((item, index)=>{
              return (
                <tr key={index}>
                <td>{item?.first_name} {item?.last_name}</td>
                <td><NavLink to={`/${userinfo?.company_slug}/prospects/view/${item?.prospect_id}/${item?.id}`}>
                    {item?.prospect_first_name + ' ' + item?.prospect_last_name}</NavLink>
                </td>
                <td>{item?.mobile_phone}</td>
                <td>{item?.email}</td>
                <td>{item?.street_address_1}</td>
                <td>{item?.zipcode}</td>
                <td>{item?.location_name}</td>
                <td>
                <NavLink to={`/${userinfo?.company_slug}/prospects/view-guardians/${item?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>

                 <button onClick={()=>DeleteHandle(item?.id)}><i className="fa-solid fa-trash"></i></button>
                </td>
            </tr>
              )
             })}  
             
             

             </tbody>

          </table>
      </div>
      :
      <h6>No data found</h6>
}

<div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
            <label style={{ marginRight: '10px' }}>Items per page:</label>
            <select className='form-control' value={itemsPerPage} onChange={handleItemsPerPageChange}
              style={{
                width: '100%',
                maxWidth: '150px',
                fontSize: '16px',
                padding: '10px',
                margin: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxSizing: 'border-box',
              }}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20px',
            }}>
              <button onClick={handlePreviousPage} disabled={activePage === 1} style={{ marginRight: '10px' }}>Previous</button>
              <ul className='pagination' style={{
                display: 'flex',
                alignItems: 'center',
                listStyleType: 'none',
                padding: 0,
                margin: 0,
              }}>
                {renderPagination()}
              </ul>
              <button onClick={handleNextPage} disabled={activePage === numberOfPages} style={{ marginLeft: '10px' }}>Next</button>
            </div>
          </div>

      </div>
      <Modal show={show} onHide={handleClose}  size="lg">
      <Modal.Header >
        <Modal.Title>Export CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className='mb-3'>Select Column</h6>
        <ul className='columnlist'>
          {columnName.map((column, index)=>{
            return (
              <li key={index}>
              <input type="checkbox" name="column"  id={`column${index}`} value={column.value} onChange={(e)=>CheckboxHandle(index,e)} />
              <label htmlFor={`column${index}`}>{column.key}</label>
          </li>
            )
          
          })}
          
        </ul>
        
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-warning' onClick={handleClose}>
          Close
        </button>
        <button className='btn btn-primary' onClick={ExportHandle}>
          Export Now
        </button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}

export default GuardiansList