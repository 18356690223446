import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetSyllabusRequest, DeleteSyllabusRequest, GetGroupRequest, GetSubjectChangeRequest, GetGradeRequest, GetSubjectRequest, GetTopicRequest, GetModuleRequest, GetSyllabusTopicRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import $ from 'jquery';
import 'select2';


const Syllabus = () => {
  const [sname, setsname] = useState("")
  const [subname, setsubname] = useState("")
  const [sgroup, setsgroup] = useState("")
  const [sgrade, setsgrade] = useState("")
  const [modulename, setmodulename] = useState("")
  const [topicname, settopicname] = useState("")
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [show, setshow] = useState(null);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [Search, setSearch] = useState("");
  const [filteredSyllabus, setFilteredSyllabus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page

  const [selectedTopic, setSelectedTopic] = useState("");

  const [showSyllabusIndex, setShowSyllabusIndex] = useState(null);
  const [showModuleIndex, setShowModuleIndex] = useState(null);

  const ShowHideHandle = (i) => {
    setshow(show === i ? null : i);
  }


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var syllabusList = fetchacompanyReducer?.GetSyllabusResponse?.results
  var groupList = fetchacompanyReducer?.GetGroupResponse?.results
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var subList = fetchacompanyReducer?.GetSubjectChangeResponse?.results
  var ModuleList = fetchacompanyReducer?.GetModuleResponse?.results
  // var TopicList = fetchacompanyReducer?.GetSyllabusTopicResponse?.results
  var TopicList = fetchacompanyReducer?.GetTopicResponse?.results

  console.log(fetchacompanyReducer?.GetSyllabusTopicResponse?.results)

  const dispatch = useDispatch()

  const columns = [
    {
      name: 'Syllabus Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Code',
      selector: row => row.code,
      sortable: true,
    },
    {
      name: 'Group Name',
      selector: row => row.group_name,
      sortable: true,
    },
    {
      name: 'Grade Name',
      selector: row => row.grade_name,
      sortable: true,
    },
    {
      name: 'Subject Name',
      selector: row => row.subject_name,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink to={`/${userinfo?.company_slug}/curriculum/view-syllabus/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
          <NavLink to={`/${userinfo?.company_slug}/curriculum/edit-syllabus/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
          <button className='table-btn' onClick={() => DeleteHandle(row.id)}><i className="fa-solid fa-trash"></i></button>

        </>
      ),
    },
  ];

  // useEffect(() => {
  //   const result = syllabusList && syllabusList.filter(data => {
  //     return data.name.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilterResult(result)
  // }, [search])



  useEffect(() => {
    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': ''
    }
    let obj1 = {
      'name': '',
      'group_id': '',
    }
    let obj2 = {
      'name': '',
      'group_id': '',
      'syllabus_id': '',
      'module_id': '',
    }
    dispatch(GetSyllabusRequest(obj))
    dispatch(GetGroupRequest())
    dispatch(GetGradeRequest())
    dispatch(GetSubjectRequest(obj1))
    dispatch(GetModuleRequest(obj1))
    dispatch(GetTopicRequest(obj2))
  }, [])

  const handleSyllabusToggle = (index) => {
    setShowSyllabusIndex(showSyllabusIndex === index ? null : index);
    setShowModuleIndex(null); // Reset module index when toggling syllabus
  };

  const handleModuleToggle = (moduleIndex) => {
    setShowModuleIndex(showModuleIndex === moduleIndex ? null : moduleIndex);
  };

  useEffect(() => {
    // Filter syllabus based on the selected topic
    if (selectedTopic) {
      const filtered = syllabusList.filter(syllabus =>
        syllabus.topic_list.some(topic => topic.name === selectedTopic)
      );
      setFilteredSyllabus(filtered);
    } else {
      setFilteredSyllabus(syllabusList);
    }
  }, [selectedTopic, syllabusList]);

  useEffect(() => {
    // Filter syllabus based on the search input
    if (search) {
      const filtered = syllabusList.filter(syllabus =>
        syllabus.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSyllabus(filtered);
    } else {
      setFilteredSyllabus(syllabusList);
    }
  }, [search, syllabusList]);

  useEffect(() => {
    // Initialize Select2
    $('#topicSelect').select2({
      placeholder: '--Select Topic--',
      allowClear: true
    }).on('change', function () {
      setSelectedTopic($(this).val());
    });

    // Cleanup function to destroy Select2 instance
    return () => {
      $('#topicSelect').select2('destroy');
    };
  }, [TopicList]);

  useEffect(() => {
    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': ''
    }
    dispatch(GetSyllabusRequest(obj))
  }, [fetchacompanyReducer?.DeleteSyllabusResponse])


  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteSyllabusRequest(id))

        if (fetchacompanyReducer?.DeleteSyllabusResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }


  const ResetHandle = () => {
    setsname("")
    setsgrade("")
    setsgroup("")
    setsubname("")
    setSelectedTopic("");

    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': '',
      'module_id': '',
      'topic_id': '',
    }

    dispatch(GetSyllabusRequest(obj))
  }
  const GroupHandle = (e) => {
    var val = e.target.value
    setsgroup(val)

    let obj = {
      id: e.target.value,
      'name': search,
      'grade_id': sgrade,
      'group_id': val,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetSubjectChangeRequest(obj))
    dispatch(GetModuleRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }
  const SubjectHandle = (e) => {
    var val = e.target.value
    setsubname(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'subject_id': val,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetModuleRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }

  const GradeHandle = (e) => {
    var val = e.target.value
    setsgrade(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': val,
      'group_id': sgroup,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
  }

  const ModuleHandle = (e) => {
    var val = e.target.value
    setmodulename(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'subject_id': subname,
      'topic_id': topicname,
      'module_id': val
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }

  const TopicHandle = (e) => {
    var val = e.target.value
    settopicname(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'group_id': sgroup,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': val
    }

    dispatch(GetSyllabusRequest(obj))
  }

  useEffect(() => {
    setFilterResult(syllabusList)
  }, [fetchacompanyReducer?.GetSyllabusResponse])

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSyllabus?.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(filteredSyllabus?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value)); // Update items per page
    setCurrentPage(1); // Reset to first page when items per page changes
  };


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> Syllabus Management</h3>
        </div>



        <div className='row form-style mb-4 align-items-center'>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Syllabus Name</label>
              <input type='text' className='form-control' placeholder='Syllabus Name'
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Grade</label>
              <select className='form-control' value={sgrade}
                onChange={(e) => GradeHandle(e)}>
                <option value="">--Grade---</option>
                {gradeList && gradeList?.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.grade_name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Group</label>
              <select className='form-control' value={sgroup}
                onChange={(e) => GroupHandle(e)}>
                <option value="">--All---</option>
                {groupList && groupList?.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Subject</label>
              <select className='form-control' value={subname}
                onChange={(e) => SubjectHandle(e)}>
                <option value="">--All---</option>
                {subList && subList?.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.subject_name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          {/* <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Module</label>
              <select className='form-control' value={modulename}
                onChange={(e) => ModuleHandle(e)}>
                <option value="">--All---</option>
                {ModuleList && ModuleList?.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
          </div> */}

          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Topic</label>
              <select id="topicSelect" className='form-control' value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
                <option value="">--All Topics---</option>
                {TopicList?.map((item, i) => (
                  <option key={i} value={item?.name}>{item?.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>

              <div className='d-flex mt-4'>
                <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-lg-3'>
            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/add-syllabus`}><i className="fa-solid fa-plus"></i> Add Syllabus</NavLink>
          </div>
        </div>

        <div className='accordian-table-container custom-table'>
          <ul className='admin-ern-table'>
            <li><b>Syllabus Name</b></li>
            <li><b>Syllabus Code</b></li>
            <li><b>Group Name</b></li>
            <li><b>Grade Name</b></li>
            <li><b>Subject Name</b></li>
            <li><b>Action</b></li>
          </ul>
          {currentItems?.map((item, index) => (
            <div key={index}>
              <ul className='admin-ern-table' onClick={() => handleSyllabusToggle(index)}>
                <span className={`arrow-acc ${showSyllabusIndex === index ? 'open' : ''}`}><i className="fa-solid fa-angle-right mr-2"></i></span>
                <li>{item?.name}</li>
                <li>{item?.code}</li>
                <li>{item?.group_name}</li>
                <li>{item?.grade_name}</li>
                <li>{item.subject_name}</li>
                <li>
                  <NavLink to={`/${userinfo?.company_slug}/curriculum/view-syllabus/${item?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                  <NavLink to={`/${userinfo?.company_slug}/curriculum/edit-syllabus/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                  <button className='table-btn' onClick={() => DeleteHandle(item.id)}><i className="fa-solid fa-trash"></i></button>
                </li>
              </ul>
              {showSyllabusIndex === index && item.modules?.map((module, moduleIndex) => (
                <div key={moduleIndex}>
                  <div className="module-name" onClick={() => handleModuleToggle(moduleIndex)}>
                    <span className={`arrow-acc ${showModuleIndex === moduleIndex ? 'open' : ''}`}>
                      <i className="fa-solid fa-angle-right mr-2"></i>
                    </span>
                    <span className="ml-3">{module.name}</span>
                  </div>
                  {showModuleIndex === moduleIndex && (
                    <table className='admin-ern-table-inner-table'>
                      <thead>
                        <tr>
                          <th>Topic Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {module.topic_list?.length > 0 ? module.topic_list?.map((topic, topicIndex) => (
                          <tr key={topicIndex}>
                            <td>{topic.name}</td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan="1">No Record found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className='d-flex'>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '0px',
            '@media (max-width: 768px)': {
              flexWrap: 'wrap',
            },
            '@media (max-width: 480px)': {
              flexWrap: 'wrap',
            },
          }}>
            <label
              style={{
                marginRight: '10px',
                '@media (max-width: 768px)': {
                  marginRight: '5px',
                },
                '@media (max-width: 480px)': {
                  marginRight: '5px',
                },
              }}>Items per Page</label>
            <select className='form-control' value={itemsPerPage} onChange={handleItemsPerPageChange}
              style={{
                width: '100%',
                maxWidth: '150px',
                fontSize: '16px',
                padding: '10px',
                margin: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxSizing: 'border-box',
                '@media (max-width: 768px)': {
                  maxWidth: '100px',
                },
                '@media (max-width: 480px)': {
                  maxWidth: '80px',
                },
              }}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>

          {/* Pagination Controls */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            '@media (max-width: 768px)': {
              marginLeft: '10px',
            },
            '@media (max-width: 480px)': {
              marginLeft: '5px',
            },
          }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}
              style={{
                marginRight: '10px',
                '@media (max-width: 768px)': {
                  marginRight: '5px',
                },
                '@media (max-width: 480px)': {
                  marginRight: '5px',
                },
              }}>Previous</button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  listStyleType: 'none',
                  padding: '2px 13px',
                  height: 'auto',
                  borderRadius: '3px',
                  background: currentPage === index + 1 ? '#000' : 'transparent', // Set background color for active page
                  color: currentPage === index + 1 ? '#fff' : '#000', // Set text color for active page
                  border: '1px solid #000', // Optional: Add border for better visibility
                  margin: 2,
                  '@media (max-width: 768px)': {
                    flexWrap: 'wrap',
                  },
                  '@media (max-width: 480px)': {
                    flexWrap: 'wrap',
                  },
                }}
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
            <button onClick={handleNextPage} disabled={currentPage === totalPages}
              style={{
                marginLeft: '10px',
                '@media (max-width: 768px)': {
                  marginLeft: '5px',
                },
                '@media (max-width: 480px)': {
                  marginLeft: '5px',
                },
              }}>Next</button>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Syllabus
