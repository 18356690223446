import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import CreatePassword from "./auth/CreatePassword";
import Sidebar from "./admin/header/Sidebar";
import Topbar from "./admin/header/Topbar";
import AdminDashboard from "./admin/dashboard/AdminDashboard";
import CompanyList from "./admin/companies/CompanyList";
import AddComapny from "./admin/companies/AddComapny";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import EditCompany from "./admin/companies/EditCompany";
import ConfirmationAdded from "./admin/companies/ConfirmationAdded";
import CompanySidebar from "./company/header/CompanySidebar";
import CompanyTopbar from "./company/header/CompanyTopbar";
import ComapnyDashboard from "./company/dashboard/ComapnyDashboard";
import ProspectsList from "./company/prospects/ProspectsList";
import AddProspects from "./company/prospects/AddProspects";
import CompanyLogin from "./auth/CompanyLogin";
import ViewProspects from "./company/prospects/ViewProspects";
import EditProspects from "./company/prospects/EditProspects";
import EmployeeList from "./company/employee/EmployeeList";
import AddEmployee from "./company/employee/AddEmployee";
import GroupList from "./company/curriculum/Group/GroupList";
import AddGroup from "./company/curriculum/Group/AddGroup";
import { EditGroup } from "./company/curriculum/Group/EditGroup";
import GradeList from "./company/curriculum/grade/GradeList";
import AddGrade from "./company/curriculum/grade/AddGrade";
import EditGrade from "./company/curriculum/grade/EditGrade";
import SubjectList from "./company/curriculum/subject/SubjectList";
import AddSubject from "./company/curriculum/subject/AddSubject";
import EditSubject from "./company/curriculum/subject/EditSubject";
import EditEmployee from "./company/employee/EditEmployee";
import ViewEmployee from "./company/employee/ViewEmployee";
import Syllabus from "./company/curriculum/syllabus/Syllabus";
import AddSyllabus from "./company/curriculum/syllabus/AddSyllabus";
import ViewSyllabus from "./company/curriculum/syllabus/ViewSyllabus";
import EditSyllabus from "./company/curriculum/syllabus/EditSyllabus";
import ViewTitle_CP from "./company/settings/Title_CP/ViewTitle_CP";
import AddTitle_CP from "./company/settings/Title_CP/AddTitle_CP";
import EditTitle_CP from "./company/settings/Title_CP/EditTitle_CP";
import ViewBackgroundCheckCompany from "./company/settings/BackgroundCheckCompany/ViewBackgroundCheckCompany";
import AddBackgroundCheckCompany from "./company/settings/BackgroundCheckCompany/AddBackgroundCheckCompany";
import EditBackgroundCheckCompany from "./company/settings/BackgroundCheckCompany/EditBackgroundCheckCompany";
import ViewTraining from "./company/Training/ViewTraining";
import TrainingDetails from "./company/Training/TrainingDetails";
import AddTraining from "./company/Training/AddTraining";
import EditTraining from "./company/Training/EditTraining";
import ViewModule from "./company/curriculum/syllabus/ViewModule";
import ViewTopic from "./company/curriculum/syllabus/ViewTopic";
import GuardiansList from "./company/guardians/GuardiansList";
import Addguardians from "./company/guardians/Addguardians";
import Editguardians from "./company/guardians/Editguardians";
import Viewguardians from "./company/guardians/Viewguardians";
import CustomersList from "./company/customers/CustomersList";
import Addcustomers from "./company/customers/Addcustomers";
import Editcustomers from "./company/customers/Editcustomers";
import Viewcustomers from "./company/customers/Viewcustomers";
import LocationList from "./company/location/LocationList";
import Addlocation from "./company/location/Addlocation";
import Editlocation from "./company/location/Editlocation";
import Viewlocation from "./company/location/Viewlocation";
import ViewGardianMain from "./company/guardians/ViewGardianMain";
import StudentList from "./company/students/StudentList";
import Addstudents from "./company/students/Addstudents";
import Editstudents from "./company/students/Editstudents";
import Viewstudents from "./company/students/Viewstudents";
import ViewStudentsMain from "./company/students/ViewstudentMain";
import EmployeeLogin from "./auth/EmployeeLogin";
import ViewModuleType from "./company/settings/Module_Type/Viewmodule_type";
import AddModuleType from "./company/settings/Module_Type/Addmodule_type";
import Viewschedule from "./company/Test/Viewschedule";
import Addschedule from "./company/Test/Addschedule";
import Editschedule from "./company/Test/Editschedule";
import Addquestion from "./company/Test/Addquestion";
import Addtest from "./company/Test/Addtest";
import Edittest from "./company/Test/Edittest";
import Viewtest from "./company/Test/Viewtest";
import AddAssessment from "./company/Test/Addassessment";
import EditAssessment from "./company/Test/Editassessment";
import Assessmentdetails from "./company/Test/Assessmentdetails";
import ViewAssessment from "./company/Test/Viewassessment";
import ViewProfile from "./company/settings/MyProfile/ViewProfile";
import ProspectLogin from "./auth/ProspectLogin";
import GuardianLogin from "./auth/GuardianLogin";
import QuestionDetails from "./company/Test/QuestionDetails";
import CommonLogin from "./auth/CommonLogin";
import ApplicationDashboard from "./company/dashboard/ApplicationDashboard";
import Assessment from "./student/Assessment";
import EmployeeDashboard from "./company/dashboard/EmployeeDashboard";
import AttendenceList from "./employee/AttendenceList";
import ReportCard from "./student/ReportCard";
import ViewAnswer from "./student/ViewAnswer";
import ScheduleList from "./student/ScheduleList";
import GuardianscheduleList from "./guardian/scheduleList";
import GuardianstudentList from "./guardian/studentList";
import GuardianstudentDetails from "./guardian/studentDetails";
import GuardianstudentAssessment from "./guardian/StudentAssessment";
import GuardianstudentEnrollment from "./guardian/StudentEnrollment";
import GuardianStudentRewards from "./guardian/studentRewards";
import GuardianStudentTeacher from "./guardian/StudentTeacher";
import GuardianStudentDocuments from "./guardian/StudentDocuments";
import GuardianStudentActivities from "./guardian/StudentActivities";
import GuardianStudentSchedule from "./guardian/StudentSchedule";
import GuardianScheduleHelpdesk from "./guardian/scheduleHelpdesk";
import GuardianScheduledSession from "./guardian/scheduledSession";
import GuardianTrailScheduledSession from "./guardian/scheduleTrailSession";
import GuardianStudentAttendance from "./guardian/studentAttendance";
import ProspectsActivities from "./company/prospects/ProspectActivities";
import ProspectsDocuments from "./company/prospects/ProspectDocuments";
import LeadInterest from "./company/settings/LeadInterest/LeadInterest";
import LeadSource from "./company/settings/LeadSource/LeadSource";
import AttendanceList from "./company/AttendanceList";



import StudentServiceList from "./student/ServiceList";
import StudentActivities from "./student/Activities";
import StudentAttendance from "./student/Attendance";
import StudentDocument from "./student/Document";
import StudentRewards from "./student/Rewards";
import StudentTeachers from "./student/Teachers";
import StudentHomeworks from "./student/Homeworks";



import Rewards from "./company/students/StudentDigitalrewards";
import AssessmentChart from "./company/students/AssessmentChart";
import AssessmentResult from "./company/students/AssessmentResult";
import AssessmentAnswer from "./company/students/AssessmentAnswer";
import LearningPlanManager from "./company/students/LearningPlanManager";
import ViewProgressCheck from "./company/students/ViewProgressCheck";
import CreateProgressCheck from "./company/students/CreateProgressCheck";
import StudentLearningpath from "./company/students/StudentLearningpath";
import LearningPathMain from "./company/students/LearningPathMain";
import ServiceList from "./company/ServicesPricing/ServiceList";
import MembershipList from "./company/ServicesPricing/MembershipList";
import AddMembership from "./company/ServicesPricing/AddMembership";
import EditMembership from "./company/ServicesPricing/EditMembership";
import PurchaseRegistration from "./company/ServicesPricing/PurchaseRegistration";
import PendingRegistration from "./company/ServicesPricing/PendingRegistration";
import StudentDetails from "./company/enrollmentDetails/StudentDetails";
import GuardianDetails from "./company/enrollmentDetails/GuardianDetails";
import BilllingDetails from "./company/enrollmentDetails/BilllingDetails";
import GuidelineDetails from "./company/enrollmentDetails/GuidelineDetails";
import EnrollmentCost from "./company/enrollmentDetails/EnrollmentCost";
import StudentEnrollmentDetails from "./company/students/StudentEnrollmentDetails";
import ViewStudentLearningpath from "./company/students/ViewStudentLearningpath";
import DigitalWorkoutPlan from "./company/students/DigitalWorkoutPlan";
import AddEmailTemplate from "./company/settings/EmailTemplate/AddEmailTemplate";
import EditEmailTemplate from "./company/settings/EmailTemplate/EditEmailTemplate";
import ViewEmailTemplate from "./company/settings/EmailTemplate/ViewEmailTemplate";
import PaymentLink from "./company/students/PaymentLink";
import ActiveServicesList from "./company/students/ActiveServiceList";
import ReportList from "./company/Reports/ReportsList";
import AddRolePermission from "./company/settings/RolePermission/AddRolePermission";
import ViewRolePermission from "./company/settings/RolePermission/ViewRolePermission";
import ProspectDocuments from "./company/prospects/ProspectDocuments";

function App() {

  const token = sessionStorage.getItem('mtb_access_token');
  const sidebarReducer = useSelector((state) => state.sidebarSlice?.isSidebarshow)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))


  var DynmicClass = ""

  if(sidebarReducer === true)
  {
    DynmicClass = ""
  }
  if(sidebarReducer === false)
  {
    DynmicClass = "closes"
  }

  return (
    <div className={`App flex-layout ${DynmicClass}`}>
      <HashRouter> 
      {/* <BrowserRouter> */}

        {token &&

          <>
            {userinfo?.user_type == "ADMIN" ? <><Topbar /> <Sidebar /></> : <> <CompanySidebar /> <CompanyTopbar />  </>}
          </>

        }



        {/* roleId == "admin" ? <> <Topbar/> <Sidebar/>  </> :<> <CompanySidebar/> <CompanyTopbar/>  </> */}

        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login/forgetPassword/reset/:base/:id" element={<CreatePassword />} />
            <Route path="/:slag/login" element={<CompanyLogin />} />
            {/* <Route path="/:slag/employee/login" element={<EmployeeLogin />} />
            <Route path="/:slag/prospect/login" element={<ProspectLogin />} />
            <Route path="/:slag/guardian/login" element={<GuardianLogin />} /> */}

            <Route path="/:slag/application/login" element={<CommonLogin />} />


          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/companyList" element={<CompanyList />} />
            <Route path="/admin/Add-company" element={<AddComapny />} />
            <Route path="/admin/edit-company/:id" element={<EditCompany />} />
            <Route path="/admin/Add-company/confirmation" element={<ConfirmationAdded />} />
            <Route path={`/${userinfo?.company_slug}/dashboard`} element={<ComapnyDashboard />} />
            <Route path={`/${userinfo?.company_slug}/employee/dashboard`} element={<EmployeeDashboard />} />
            <Route path={`/${userinfo?.company_slug}/application/dashboard`} element={<ApplicationDashboard />} />
            <Route path={`/${userinfo?.company_slug}/prospects`} element={<ProspectsList />} />
            <Route path={`/${userinfo?.company_slug}/prospects/add-prospects`} element={<AddProspects />} />
            <Route path={`/${userinfo?.company_slug}/prospects/view/:id/:pros`} element={<ViewProspects />} />
            <Route path={`/${userinfo?.company_slug}/prospects/edit/:id`} element={<EditProspects />} />
            <Route path={`/${userinfo?.company_slug}/employee`} element={<EmployeeList />} />
            <Route path={`/${userinfo?.company_slug}/employee/add-employee`} element={<AddEmployee />} />
            <Route path={`/${userinfo?.company_slug}/employee/edit/:id`} element={<EditEmployee />} />
            <Route path={`/${userinfo?.company_slug}/employee/view/:id`} element={<ViewEmployee />} />

            <Route path={`/${userinfo?.company_slug}/curriculum/group`} element={<GroupList />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/group/add-group`} element={<AddGroup />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/group/edit-group/:id`} element={<EditGroup />} />

            <Route path={`/${userinfo?.company_slug}/curriculum/grade`} element={<GradeList />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/grade/add-grade`} element={<AddGrade />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/grade/edit-grade/:id`} element={<EditGrade />} />

            <Route path={`/${userinfo?.company_slug}/curriculum/subject`} element={<SubjectList />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/subject/add-subject`} element={<AddSubject />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/subject/edit-subject/:id`} element={<EditSubject />} />

            <Route path={`/${userinfo?.company_slug}/curriculum/syllabus`} element={<Syllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/add-syllabus`} element={<AddSyllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/view-syllabus/:id`} element={<ViewSyllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/edit-syllabus/:id`} element={<EditSyllabus />} />

            <Route path={`/${userinfo?.company_slug}/settings/Title_CP`} element={<ViewTitle_CP />} />
            <Route path={`/${userinfo?.company_slug}/settings/Title_CP/AddTitle_CP`} element={<AddTitle_CP />} />
            <Route path={`/${userinfo?.company_slug}/settings/Title_CP/EditTitle_CP/:id`} element={<EditTitle_CP />} />

            <Route path={`/${userinfo?.company_slug}/settings/BackgroundCheckCompany`} element={<ViewBackgroundCheckCompany />} />
            <Route path={`/${userinfo?.company_slug}/settings/BackgroundCheckCompany/AddBackgroundCheckCompany`} element={<AddBackgroundCheckCompany />} />
            <Route path={`/${userinfo?.company_slug}/settings/BackgroundCheckCompany/EditBackgroundCheckCompany/:id`} element={<EditBackgroundCheckCompany />} />

            <Route path={`/${userinfo?.company_slug}/Training/ViewTraining`} element={<ViewTraining />} />
            <Route path={`/${userinfo?.company_slug}/Training/AddTraining`} element={<AddTraining />} />
            <Route path={`/${userinfo?.company_slug}/Training/EditTraining/:id`} element={<EditTraining />} />
            <Route path={`/${userinfo?.company_slug}/Training/TrainingDetails/:id`} element={<TrainingDetails />} />

            <Route path={`/${userinfo?.company_slug}/curriculum/syllabus`} element={<Syllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/add-syllabus`} element={<AddSyllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/view-syllabus/:id`} element={<ViewSyllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/edit-syllabus/:id`} element={<EditSyllabus />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/view-module/:id`} element={<ViewModule />} />
            <Route path={`/${userinfo?.company_slug}/curriculum/view-topic/:id`} element={<ViewTopic />} />

            <Route path={`/${userinfo?.company_slug}/guardians`} element={<GuardiansList />} />
            <Route path={`/${userinfo?.company_slug}/prospects/add-guardians/:id/:pros`} element={<Addguardians />} />
            <Route path={`/${userinfo?.company_slug}/prospects/edit-guardians/:id`} element={<Editguardians />} />
            <Route path={`/${userinfo?.company_slug}/prospects/view-guardians/:pros/:id`} element={<Viewguardians />} />
            <Route path={`/${userinfo?.company_slug}/prospects/view-guardians/:id`} element={<ViewGardianMain />} />

            <Route path={`/${userinfo?.company_slug}/customers`} element={<CustomersList />} />
            <Route path={`/${userinfo?.company_slug}/customers/add-customers`} element={<Addcustomers />} />
            <Route path={`/${userinfo?.company_slug}/customers/edit-customers/:id`} element={<Editcustomers />} />
            <Route path={`/${userinfo?.company_slug}/customers/view-customers/:id`} element={<Viewcustomers />} />
        
            <Route path={`/${userinfo?.company_slug}/location`} element={<LocationList />} />
            <Route path={`/${userinfo?.company_slug}/location/add-location`} element={<Addlocation />} />
            <Route path={`/${userinfo?.company_slug}/location/edit-location/:id`} element={<Editlocation />} />
            <Route path={`/${userinfo?.company_slug}/location/view-location/:id`} element={<Viewlocation />} />

            <Route path={`/${userinfo?.company_slug}/students`} element={<StudentList />} />
            <Route path={`/${userinfo?.company_slug}/prospects/add-students/:id/:pros`} element={<Addstudents />} />
            <Route path={`/${userinfo?.company_slug}/prospects/edit-students/:id`} element={<Editstudents />} />
            <Route path={`/${userinfo?.company_slug}/prospects/view-students/:pros/:id`} element={<Viewstudents />} />
            <Route path={`/${userinfo?.company_slug}/students/view-students/:id`} element={<ViewStudentsMain />} />
        
            <Route path={`/${userinfo?.company_slug}/settings/Module_Type`} element={<ViewModuleType />} />
            <Route path={`/${userinfo?.company_slug}/settings/Module_Type/AddModule_Type`} element={<AddModuleType />} />
          

            <Route path={`/${userinfo?.company_slug}/Test/Viewschedule`} element={<Viewschedule />} />
            <Route path={`/${userinfo?.company_slug}/Test/Addschedule`} element={<Addschedule />} />
            <Route path={`/${userinfo?.company_slug}/Test/Editschedule/:id`} element={<Editschedule />} />
            <Route path={`/${userinfo?.company_slug}/Test/Addtest/:id`} element={<Addtest />} />
            <Route path={`/${userinfo?.company_slug}/Test/Edittest/:id`} element={<Edittest />} />
            <Route path={`/${userinfo?.company_slug}/Test/Addquestion`} element={<Addquestion />} />
            <Route path={`/${userinfo?.company_slug}/Test/edit-question/:id/:pros`} element={<Edittest />} />
            <Route path={`/${userinfo?.company_slug}/Test/Viewtest`} element={<Viewtest />} />
            <Route path={`/${userinfo?.company_slug}/Test/Addassessment`} element={<AddAssessment />} />
            <Route path={`/${userinfo?.company_slug}/Test/EditAssessment/:id`} element={<EditAssessment />} />
            <Route path={`/${userinfo?.company_slug}/Test/Viewassessment`} element={<ViewAssessment />} />
            <Route path={`/${userinfo?.company_slug}/Test/Assessmentdetails/:id`} element={<Assessmentdetails />} />

            <Route path={`/${userinfo?.company_slug}/settings/MyProfile`} element={<ViewProfile />} />
            <Route path={`/${userinfo?.company_slug}/assesment/view-question/:id/:pros`} element={<QuestionDetails />} />

            <Route path={`/${userinfo?.company_slug}/application/student/assesment`} element={<Assessment />} />
            <Route path={`/${userinfo?.company_slug}/application/student/reportcard/:id`} element={<ReportCard />} />
            <Route path={`/${userinfo?.company_slug}/application/student/ScheduleList`} element={<ScheduleList />} />
            <Route path={`/${userinfo?.company_slug}/application/student/viewanswer/:id`} element={<ViewAnswer />} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentServiceList`} element={< StudentServiceList/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentActivities`} element={< StudentActivities/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentAttendance`} element={< StudentAttendance/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentDocument`} element={< StudentDocument/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentRewards`} element={< StudentRewards/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/StudentTeachers`} element={< StudentTeachers/>} />
            <Route path={`/${userinfo?.company_slug}/application/student/Homeworks`} element={<StudentHomeworks />} />

            <Route path={`/${userinfo?.company_slug}/students/rewards`} element={<Rewards />} />
            <Route path={`/${userinfo?.company_slug}/students/assessmentchart/:id/:pros`} element={<AssessmentChart />} />
            <Route path={`/${userinfo?.company_slug}/students/assessmentanswer/:id`} element={<AssessmentAnswer />} />
            <Route path={`/${userinfo?.company_slug}/students/assessmentresult/:id/:pros`} element={<AssessmentResult />} />
            <Route path={`/${userinfo?.company_slug}/employee-app/my-attendence`} element={<AttendenceList />} />
            <Route path={`/${userinfo?.company_slug}/students/learningplanmanager/:id/:pros/:lpid`} element={<LearningPlanManager />} />
            <Route path={`/${userinfo?.company_slug}/students/CreateProgressCheck/:id/:pros/:lpid`} element={<CreateProgressCheck />} />
            <Route path={`/${userinfo?.company_slug}/students/ViewProgressCheck/:id/:pros/:lpid`} element={<ViewProgressCheck />} />
            <Route path={`/${userinfo?.company_slug}/students/StudentLearningpath/:id/:pros`} element={<StudentLearningpath />} />
            <Route path={`/${userinfo?.company_slug}/students/ViewStudentLearningpath/:id/:pros`} element={<ViewStudentLearningpath />} />
            <Route path={`/${userinfo?.company_slug}/students/LearningPathMain/:id`} element={<LearningPathMain />} />
            <Route path={`/${userinfo?.company_slug}/ServiceList`} element={<ServiceList />} />
            <Route path={`/${userinfo?.company_slug}/MembershipList`} element={<MembershipList />} />
            <Route path={`/${userinfo?.company_slug}/AddMembership`} element={<AddMembership />} />
            <Route path={`/${userinfo?.company_slug}/EditMembership/:id`} element={<EditMembership />} />
            <Route path={`/${userinfo?.company_slug}/PurchaseRegistration`} element={<PurchaseRegistration />} />
            <Route path={`/${userinfo?.company_slug}/PendingRegistration`} element={<PendingRegistration />} />
            <Route path={`/${userinfo?.company_slug}/StudentDetails`} element={<StudentDetails />} />
            <Route path={`/${userinfo?.company_slug}/GuardianDetails`} element={<GuardianDetails />} />
            <Route path={`/${userinfo?.company_slug}/BillingDetails`} element={<BilllingDetails />} />
            <Route path={`/${userinfo?.company_slug}/GuidelineDetails`} element={<GuidelineDetails />} />
            <Route path={`/${userinfo?.company_slug}/EnrollmentCost`} element={<EnrollmentCost />} />
            <Route path={`/${userinfo?.company_slug}/StudentEnrollmentDetails/:id`} element={<StudentEnrollmentDetails />} />
            <Route path={`/${userinfo?.company_slug}/DigitalWorkoutPlan/:id/:date/:pros/:pros2/:studentName`} element={<DigitalWorkoutPlan />} />
            <Route path={`/${userinfo?.company_slug}/settings/ViewEmailTemplate`} element={<ViewEmailTemplate />} />
            <Route path={`/${userinfo?.company_slug}/settings/EmailTemplate/AddEmailTemplate`} element={<AddEmailTemplate />} />
            <Route path={`/${userinfo?.company_slug}/settings/EmailTemplate/EditEmailTemplate/:id`} element={<EditEmailTemplate />} />
            <Route path={`/${userinfo?.company_slug}/students/PaymentLink/:id/:pros/:sid`} element={<PaymentLink />} />
            <Route path={`/${userinfo?.company_slug}/students/ActiveServicesList/:id`} element={<ActiveServicesList />} />
            <Route path={`/${userinfo?.company_slug}/Report/ReportList`} element={<ReportList />} />

            <Route path={`/${userinfo?.company_slug}/application/guardian/scheduleList`} element={<GuardianscheduleList />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentList`} element={<GuardianstudentList />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentDetails/:id`} element={<GuardianstudentDetails />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentAssessment`} element={<GuardianstudentAssessment />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentEnrollment`} element={<GuardianstudentEnrollment />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentRewards`} element={<GuardianStudentRewards />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentTeacher`} element={<GuardianStudentTeacher />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentAttendance`} element={<GuardianStudentAttendance />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentDocuments`} element={<GuardianStudentDocuments />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentActivities`} element={<GuardianStudentActivities />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/studentSchedule`} element={<GuardianStudentSchedule />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/scheduleHelpdesk`} element={<GuardianScheduleHelpdesk />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/scheduledSession`} element={<GuardianScheduledSession />} />
            <Route path={`/${userinfo?.company_slug}/application/guardian/GuardianTrailSchedule`} element={<GuardianTrailScheduledSession />} />
            <Route path={`/${userinfo?.company_slug}/settings/RolePermission/AddRolePermission`} element={<AddRolePermission />} />
            <Route path={`/${userinfo?.company_slug}/settings/RolePermission/ViewRolePermission`} element={<ViewRolePermission />} />
            <Route path={`/${userinfo?.company_slug}/prospects/ProspectsDocuments/:id/:pros`} element={<ProspectsDocuments />} />
            <Route path={`/${userinfo?.company_slug}/prospects/ProspectsActivities/:id/:pros`} element={<ProspectsActivities />} />
            
            <Route path={`/${userinfo?.company_slug}/settings/LeadInterest`} element={<LeadInterest />} />
            <Route path={`/${userinfo?.company_slug}/settings/LeadSource`} element={<LeadSource />} />
            <Route path={`/${userinfo?.company_slug}/attendance`} element={<AttendanceList />} />


          </Route>


        </Routes>
      {/* </BrowserRouter> */}
      </HashRouter>
    </div>
  );
}

export default App;
