import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { DeleteattendanceRequest, UpdateattendanceRequest, AddattendanceRequest, GetattendanceRequest, FetchattendanceRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Student_Attendance = () => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const [trainingmodule, settrainingmodule] = useState("")
  const [trainingmodule1, settrainingmodule1] = useState("")
  const [clockin, setclockin] = useState("")
  const [clockin1, setclockin1] = useState("")
  const [clockout, setclockout] = useState("")
  const [clockout1, setclockout1] = useState("")
  const [punchdate, setpunchdate] = useState("")
  const [punchdate1, setpunchdate1] = useState("")
  const [remarks, setremarks] = useState("")
  const [remarks1, setremarks1] = useState("")
  const [systemin, setsystemin] = useState("")
  const [systemin1, setsystemin1] = useState("")
  const [systemout, setsystemout] = useState("")
  const [systemout1, setsystemout1] = useState("")

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const [FilterResult, setFilterResult] = useState([])

  let navigate = useNavigate()
  const { id } = useParams()

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var attendanceList = fetchacompanyReducer?.GetattendanceResponse?.results[0]
  var attendanceView = fetchacompanyReducer?.FetchattendanceResponse?.results[0]

  console.log(fetchacompanyReducer.GetattendanceResponse)
  console.log('FetchattendanceResponse_editinfo',clockin1, clockout1);

  const dispatch = useDispatch()

  const formatTime = (time) => {
    if (!time) return ""; // Handle null or undefined time
    const [hours, minutes] = time.split(':');
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  useEffect(() => {
    const editinfo = fetchacompanyReducer?.FetchattendanceResponse?.results[0];
    console.log('FetchattendanceResponse_editinfo', editinfo);
    if (editinfo) {
      setclockin1(formatTime(editinfo?.login_time) || "");
      setclockout1(formatTime(editinfo?.logout_time) || "");
      setpunchdate1(editinfo?.attendance_date || "");
      setremarks1( editinfo?.remarks || "");
    }
  }, [fetchacompanyReducer?.FetchattendanceResponse, show1]); 

  useEffect(() => {
    let obj = {
      'employee_id': '',
      'student_id': Number(id),
    }
    dispatch(GetattendanceRequest(obj))
  }, [fetchacompanyReducer?.DeleteattendanceResponse])

  const handleShow1 = (tid, did) => {
    console.log("handleShow1", tid);
    seteditrowId(tid);
    setShow1(true);
    // Fetch the attendance data for the selected record
    dispatch(FetchattendanceRequest({ rowId: tid, id: id, did: did }));
  };

  const GamePlanHandle = (tid, date, pros, pros2, studentName) => {
    console.log("GamePlanHandle", tid, date, pros, pros2, studentName);

    const baseUrl = `${window.location.origin}/#/${userinfo.company_slug}`;
    const fullUrl = `${baseUrl}/DigitalWorkoutPlan/${tid}/${date}/${pros}/${pros2}/${encodeURIComponent(studentName)}`;

      // Use the student's name or ID as the tab name
    const tabName = `${studentName}`;

    window.open(fullUrl, tabName);
  };

  useEffect(() => {
    setShow1(false)
    let obj = {
      'employee_id': '',
      'student_id': Number(id),
    }
    dispatch(GetattendanceRequest(obj))
  }, [fetchacompanyReducer?.UpdateattendanceResponse])

  // useEffect(() => {

  //   let obj = {
  //     'employee_id': '',
  //     'student_id': Number(id),
  //   }
  //   dispatch(GetattendanceRequest(obj))
  // }, [])

  const DeleteHandle = (did) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteattendanceRequest( {student_id: id, id: did}))

        if (fetchacompanyReducer?.DeleteattendanceResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = () => {

    if (clockin == '') {
      Swal.fire({
        icon: "error",
        text: 'Clock In should be must',
      });

    } else {

      let obj = {
        "attendance_date": punchdate,
        "login_time": clockin,
        "logout_time": clockout,
        "remarks": remarks,

      }

      dispatch(AddattendanceRequest({ obj: obj, student_id: id, navigate: navigate }))
    }

  }

  useEffect(() => {
    let obj = {
      'employee_id': '',
      'student_id': Number(id),
    }
    dispatch(GetattendanceRequest(obj));
    setShow(false)
  }, [fetchacompanyReducer?.AddattendanceResponse])

  const UpdateHandle = () => {

    if (clockout1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Clock Out should be must',
      });

    } else {

      let obj = {
        "attendance_date": punchdate1,
        "login_time": clockin1,
        "logout_time": clockout1,
        "remarks": remarks1,

      }

      dispatch(UpdateattendanceRequest({ obj: obj, student_id: id, id: editrowId }))
    }
  }

  useEffect(() => {

    setFilterResult(attendanceList)
  }, [fetchacompanyReducer?.GetattendanceResponse])

  const objectStyle = {
    backgroundColor: "rgb(215 255 201)",
  };

  const objectStyleValid = {
    backgroundColor: "white",
  };

  return (
    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Attendance</h5>
      <hr></hr>


      {userinfo?.user_type == "COMPANY" &&
        <div className='col-lg-4'>
          <button className='formBtn mb-4' onClick={handleShow}>Add Attendance</button>
        </div>
      }

      {attendanceList && attendanceList.length > 0 ?
        <div className='table-responsive'>
          <table className='custom-table'>
            <thead>
              <tr className='text-center'>
                <th>Date</th>
                <th>Clock In</th>
                <th>Clock Out</th>
                <th>Remarks</th>
                {userinfo?.user_type == "COMPANY" &&
                  <th>Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {attendanceList && attendanceList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className='text-center'>{moment(new Date(item?.attendance_date)).format("DD-MM-YYYY")}</td>
                    <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                      return (
                        <span className='timeseparate'>{formatTime(list?.login_time)} </span>
                      )
                    })}</td>
                    <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                      return (
                        <span className='timeseparate'>{formatTime(list?.logout_time)}</span>
                      )
                    })}</td>
                    <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                      return (
                        <span className='timeseparate'>{list?.remarks}</span>
                      )
                    })}</td>
                    {userinfo?.user_type == "COMPANY" &&
                      <td className='text-center'>{item?.time && item?.time.map((list, index) => {
                        const formattedLoginTime = formatTime(list?.login_time);
                        const formattedLogoutTime = formatTime(list?.logout_time);
                        const isDisabled = !formattedLoginTime || !formattedLogoutTime;

                        return (
                          <span className='timeseparate'>
                            <button className='table-btn text-info' onClick={() => GamePlanHandle(item?.student_user_id, item?.attendance_date, formatTime(list?.login_time), formatTime(list?.logout_time), `${item.student_first_name} ${item.student_last_name}`)} style={list?.logout_time === "" ? objectStyle : objectStyleValid} disabled={isDisabled}>Game Plan</button>
                            <NavLink className='mr-2' onClick={() => handleShow1(list?.id, item?.attendance_date)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                            <button onClick={() => DeleteHandle(list?.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
                          </span>
                        )
                      })}
                      </td>
                    }
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        :
        <h6 className='text-center'>No data found</h6>
      }

      {/* <div className='table-responsive'>
        <DataTable
          columns={columns}
          data={FilterResult}
          pagination
          highlightOnHover
        />
      </div> */}




      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Attendance Information</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Date</label>
                <input type='date' className='form-control' value={punchdate}
                  onChange={(e) => setpunchdate(e.target.value)} placeholder='Punch Date' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock In Time</label>
                <input type='time' className='form-control' value={clockin}
                  onChange={(e) => setclockin(e.target.value)} placeholder='Clock In Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock Out Time</label>
                <input type='time' className='form-control' value={clockout}
                  onChange={(e) => setclockout(e.target.value)} placeholder='Clock Out Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Remarks</label>
                <input type='text' className='form-control' value={remarks}
                  onChange={(e) => setremarks(e.target.value)} placeholder='Remarks' />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Attendance Information</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Date</label>
                <input type='date' className='form-control' value={punchdate1}
                  onChange={(e) => setpunchdate1(e.target.value)} placeholder='Punch Date' />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock In Time</label>
                <input type='time' className='form-control' value={clockin1}
                  onChange={(e) => setclockin1(e.target.value)} placeholder='Clock In Time' />

              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock Out Time</label>
                <input type='time' className='form-control' value={clockout1}
                  onChange={(e) => setclockout1(e.target.value)} placeholder='Clock Out Time' />

              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Remarks</label>
                <input type='text' className='form-control' value={remarks1}
                  onChange={(e) => setremarks1(e.target.value)}
                  placeholder='Remarks' />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default Student_Attendance