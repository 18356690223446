import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetStudentActivityRequest, DeleteStudentActivityRequest, UpdateStudentActivityRequest, AddStudentActivityRequest, FetchStudentActivityRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { min } from "moment";
import ApiConnection from '../../utils/ApiConnection'


const Student_Schedule = () => {


    const dispatch = useDispatch()
    const { id, pros } = useParams()
    console.log(id, pros)


    const [search, setsearch] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    const [remainderdate1, setremainderdate1] = useState(null)
    const [communicationtype1, setcommunicationtype1] = useState("")
    const [stdId, setstdId] = useState(id)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const [FilterResult, setFilterResult] = useState([])

    let navigate = useNavigate()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var StudentActivityList = fetchacompanyReducer?.GetStudentActivityResponse?.results
    var StudentActivityView = fetchacompanyReducer?.FetchStudentActivityResponse?.results[0]
    console.log(fetchacompanyReducer.GetStudentActivityResponse)
    console.log("setstdId", stdId)


    useEffect(() => {
        let obj = {
            id: id,
            student_id: pros,
        }
        dispatch(FetchStudentActivityRequest(obj))
    }, [])

    const GetSchedule = async (e) => {
        try {
            const response = await ApiConnection.get(
                `student-service-schedule-crud/?server_type=production&student_id=${id}`
            );
            if (response?.status === 200) {
                console.log('student-service-schedule-crud', response);
                setAvilScheduleList(response?.data?.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetSchedule()

    }, [])

    const DeleteHandle = async (id, Delid) => {
        console.log('ScheduleCancel', id, Delid)

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Cancel it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.post(`student-service-schedule-cancel/?server_type=production&id=${id}&student_id=${Delid}`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Cancelled!',
                            'Schedule has been Cancelled.',
                            'success'
                        )
                        // GetService()
                        // setShow5(false)
                    }
                }
            })
        } catch (e) { }
    }


    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Schedule</h5>
            <div className='row form-style'>
                <div className='col-lg-4'>
                    {/* <NavLink
                        to={{
                            pathname: `/${userinfo?.company_slug}/Test/Addschedule`,
                            state: { studentId: id } // Pass the student ID in state
                        }}
                        className='formBtn mb-1'
                        style={{ width: '68%' }}
                    >
                        Add Schedule
                    </NavLink> */}
                    <button className='formBtn mb-1' style={{ width: '68%' }} onClick={() => {
                      const updatedPayload = {
                        studentId: id,
                      };
                      console.log("updatedPayload", updatedPayload)
                      navigate(`/${userinfo?.company_slug}/Test/Addschedule`, { state: { updatedPayload } });
                    }}
                    >Add Schedule</button>
                </div>
            </div>

            {AvilScheduleList && AvilScheduleList.length > 0 ?
                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Schedule Date</th>
                                <th>Day Name</th>
                                <th>Schedule Time / Teacher</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {AvilScheduleList && AvilScheduleList.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{moment(item?.schedule_date).format('YYYY-MM-DD')}</td>
                                        <td>{moment(item?.schedule_date).format('dddd')}</td>
                                        {/* <td>{item?.start_time} - {item?.end_time}</td> */}
                                        <td>
                                            {item?.time_details_lists && item?.time_details_lists.map((avilitem, i) => {
                                                return (
                                                    <tr className='rowspan-2'>{avilitem?.std_service_start_time} - {avilitem?.std_service_end_time} ({avilitem?.emp_first_name})
                                                        <button onClick={() => DeleteHandle(avilitem?.id, item?.student_id)} style={{ color: 'red', width: '60px', borderRadius: '5px', border: '2px solid #ec0909' }}>Cancel</button>
                                                    </tr>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                            <button><i className="fa-solid fa-trash"></i></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>

                </div>
                :
                <h6 className='text-center'>No data found</h6>
            }

        </div>
    )
}

export default Student_Schedule