import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';


const data = [
    {
        id:1,
        question:"Question one",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_a"
    },
    {
        id:2,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },
    {
        id:3,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },
    {
        id:4,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },

]

const Assessment = () => {
    const [show, setShow] = useState(false);
    const [questionList, setQuestionList] = useState([])
    const [Index, setIndex] = useState(0)
    const [Newarry, setNewarry ] = useState([])
    const [ChooseAns, setChooseAns] = useState("")
    const [testId, settestId] = useState("")
    const [duration, setduration] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var AssessmentList = fetchstudentReducer?.GetStudentAssesmentResponse?.results
  var QuestionListreducer = fetchstudentReducer?.TakeexamResponse?.results

  console.log(Newarry)

  const dispatch = useDispatch()

  useEffect(() => {
    
      dispatch(GetStudentAssesmentRequest())
  }, [])


  useEffect(() => {
    if (duration > 0) {
      const timerId = setInterval(() => {
        setduration((prevTime) => prevTime - 1);
      }, 60000);

      return () => clearInterval(timerId);
    }
  }, [duration]);


  const NextHandle = (index, qid) => {

    if(ChooseAns == ''){
        alert('Please select any one')
    } else {
        setIndex(index + 1)

        var TempArr = [...Newarry];
        TempArr[Index] = {answer: ChooseAns, question_number: QuestionListreducer[Index]?.question_no};
       setChooseAns('');
        setNewarry(TempArr); 
    }



}

const SkipHandle = (index) => {
    if(Index == QuestionListreducer.length - 1) {
        alert("Please select") 
  }  else {
    setIndex(index + 1)
    
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: '', question_number: QuestionListreducer[Index]?.question_no};
    setChooseAns('');
    setNewarry(TempArr); 
}
} 
const BackHandle = (index) => {
    setIndex(index - 1)
    setChooseAns(Newarry[index - 1]?.answer)
    // var TempArr = [...Newarry];
    // TempArr[Index] = '';
    // setChooseAns('');
    // setNewarry(TempArr); 

}

const ResetHandle = () => {
    // Reset the selected answer for the current question
    var TempArr = [...Newarry];
    TempArr[Index] = { answer: '', question_number: questionList[Index]?.question_no };
    setChooseAns('');
    setNewarry(TempArr);
};

const RadioHandle = (value, index, key) => {
    console.log(value)
    console.log(key);
    setChooseAns(key)
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: key, question_number: QuestionListreducer[Index]?.question_no};
    setNewarry(TempArr); 
}   

const ModalHandler = (id, time) =>{
    setduration(Number(time))
    dispatch(TakeexamRequest(id))
    setShow(true);
    settestId(id)
}

// useEffect(()=>{
 
//     setQuestionList(QuestionListreducer)
// },[fetchstudentReducer?.TakeexamResponse])

const SubmitExam = () =>{
    if (ChooseAns == '') {
        alert("Please Attempt All Questions") 
    } else {
        let obj = {
            exam_questions_list:Newarry
        }
        dispatch(SubmitExamRequest({test_id:testId,student_id:userinfo?.user_id,obj:obj }))
    }
}

  const goToQuestion = (index) => {
    console.log("goToQuestion", index)
    if(Index == QuestionListreducer.length - 1) {
        alert("Please select") 
  }  else {
    setIndex(index + 1)
    
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: '', question_number: QuestionListreducer[Index]?.question_no};
    setChooseAns('');
    setNewarry(TempArr); 
}
  }

useEffect(()=>{
    setShow(false);
    setduration(0)
    dispatch(GetStudentAssesmentRequest())
},[fetchstudentReducer?.SubmitExamResponse])

 
 





  return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Assessment Management </h3>
                </div>

                {AssessmentList && AssessmentList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Start time</th>
                                    <th>End Time</th>
                                    <th>Duration</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssessmentList && AssessmentList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.test_name}</td>
                                            <td>{item?.test_date}</td>
                                            <td>{item?.start_time}</td>
                                            <td>{item?.end_time}</td>
                                            <td>{item?.time}</td>
                                            <td>
                                                {item?.is_exam_taken == 0 ?
                                                <button className="btn btn-sm btn-primary m-0" onClick={()=>ModalHandler(item?.test_id, item?.time )}>Take Test</button>
                                                :
                                                <>
                                                {/* <NavLink to={`/${userinfo?.company_slug}/application/student/viewanswer/${item?.test_id}`}>View Answer </NavLink> */}
                                                <NavLink to={`/${userinfo?.company_slug}/application/student/reportcard/${item?.test_id}`}>(View Result)</NavLink>
                                                </>
                                                 }
                                          
                                                </td>
                                        </tr>
                                    )
                                })}





                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }


            </div>
            <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='test-modal'
      >

        <Modal.Body>
                <div className='test-top'>
                    <ul>
                        <li>
                            Question: {Index + 1} / <b>{QuestionListreducer?.length}</b>
                        </li>
                        <li>
                           Time Left {duration} Minutes
                        </li>
                        <li>
                            <button className='closeBtn' onClick={()=>setShow(false)}>
                                <i className="fa-solid fa-rectangle-xmark"></i>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='p-4'>
                <div className='row'>
                    <div className='col-lg-8'>
                       
                        <div className='question-set'>
                         
                            <h3>Question {QuestionListreducer&&QuestionListreducer?.[Index]?.question_no}</h3>
                            <h5>
                            <div dangerouslySetInnerHTML={{ __html: QuestionListreducer&&QuestionListreducer?.[Index]?.question }}></div>
                               </h5>
                            <ul>
                                <li>
                                    <button  onClick={()=>RadioHandle(QuestionListreducer&&QuestionListreducer?.[Index]?.option_2 , Index, '1')}
                                   className={ChooseAns == "1" ? "selected" : ""}
                                    >{QuestionListreducer&&QuestionListreducer?.[Index]?.option_1}</button>
                                </li>
                                <li>
                                    <button className={ChooseAns == "2" ? "selected" : ""} onClick={()=>RadioHandle(QuestionListreducer&&QuestionListreducer?.[Index]?.option_2 , Index, '2')} >{QuestionListreducer&&QuestionListreducer?.[Index]?.option_2}</button>
                                </li>
                                <li>
                                    <button className={ChooseAns == "3" ? "selected" : ""} onClick={()=>RadioHandle(QuestionListreducer&&QuestionListreducer?.[Index]?.option_3 , Index, '3')} >{QuestionListreducer&&QuestionListreducer?.[Index]?.option_3}</button>
                                </li>
                                <li>
                                    <button className={ChooseAns == "4" ? "selected" : ""} onClick={()=>RadioHandle(QuestionListreducer&&QuestionListreducer?.[Index]?.option_4 , Index, '4')} >{QuestionListreducer&&QuestionListreducer?.[Index]?.option_4}</button>
                                </li>
                            </ul>
                        </div>
                        <div>
                        <ul className='questionBtn'>
                            {Index > 0 &&
                            <li>
                                <button className='btn btn-sm btn-primary' onClick={()=>BackHandle(Index)}>Prev</button>
                            </li>
                            }
                            {/* {
                               Index == QuestionListreducer?.length - 1 ? 
                               <li></li>
                               :
                               <li>
                               <button className='btn btn-sm btn-warning' onClick={()=>SkipHandle(Index)}>Skip</button>
                           </li>
                            } */}

                                    {/* Hide Skip button if an answer is selected */}
        {!ChooseAns && Index < QuestionListreducer?.length - 1 && (
            <li>
                <button className='btn btn-sm btn-warning' onClick={() => SkipHandle(Index)}>Skip</button>
            </li>
        )}
                            
                            {Index == QuestionListreducer?.length - 1 ? 
                            <li>
                                <button className='btn btn-sm btn-success' onClick={SubmitExam}>Submit</button>
                            </li>
                            :
                            <li>
                            <button className='btn btn-sm btn-success' onClick={()=>NextHandle(Index, questionList?.[Index]?.id)}>Next</button>
                        </li>
                            }
                            {ChooseAns && (
                            <li>
                            <button className='btn btn-sm btn-danger' onClick={ResetHandle}>Reset</button>
                            </li>
                            )}
                        </ul>
                    </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='test-overview'>
                            <ul className='question_noList'>
                             
                                {QuestionListreducer&&QuestionListreducer.map((item, index)=>{
                                    if(index >= Newarry.length){
                                        return (
                                            <li key={index} className='default'><span onClick={()=>goToQuestion([index])}>{index + 1}</span></li>
                                        )
                                    } else if(Newarry[index]?.answer !== ''){
                                        return (
                                            <li key={index} className='touched'><span>{index + 1}</span></li>
                                        )
                                    } else if(Newarry[index]?.answer == ''){
                                        return (
                                            <li className='not-touched' key={index}><span>{index + 1}</span></li>
                                        )
                                    }
                                    
                                })}
                            
                            </ul>
                           <ul className='question_info'>
                                <li><span className='default'></span>Default</li>
                                <li><span className='not-touched'></span> Not Touched</li>
                                <li><span className='touched'></span>Touched</li>
                              
                           </ul>
                        </div>
                                                
                    </div>
                </div>
                
                </div>
        </Modal.Body>
   
      </Modal>
        </div>
  )
}

export default Assessment