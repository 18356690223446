import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetCompanyRequest, DeleteCompanyRequest } from '../../redux/reducer/FetchAdminDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const CompanyList = () => {

  const dispatch = useDispatch()
  const fetchReducer = useSelector((state) => state.FetchAdminDataReducer)
  var companyList = fetchReducer?.GetCompanyResponse?.results
  console.log(fetchReducer)

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  useEffect(() => {

    dispatch(GetCompanyRequest())

  }, [dispatch])

    // Filtered company list based on search query
    const filteredCompanies = companyList?.filter(company =>
      company?.company_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    // Pagination logic
    const indexOfLastCompany = currentPage * itemsPerPage;
    const indexOfFirstCompany = indexOfLastCompany - itemsPerPage;
    const currentCompanies = filteredCompanies?.slice(indexOfFirstCompany, indexOfLastCompany);
  
    const totalPages = Math.ceil(filteredCompanies?.length / itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  const BASE_URL = `${window.location.protocol}`;



  const DeleteControl = (id) => {


    Swal.fire({
      title: 'Are you sure?',
      text: "Delete this company ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      let pharam = {
        id: id,
        method: 'delete'
      }

      dispatch(DeleteCompanyRequest(pharam))

      if (fetchReducer.status == "FetchAdminDataReducer/DeleteCompanyResponse") {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }

    })
  }


  return (
    <div className='common-layout'>
      <Loader visible={fetchReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Company Management</h3>
        </div>


        <div className='row form-style mb-4'>
          <div className='col-lg-4'>
            <div className='form-group'>
              <label>Company</label>
              <input type='text' className='form-control' placeholder='Search..'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='form-group'>
              <label>Status</label>
              <select className='form-control'>
                <option>All</option>
              </select>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='form-group'>
              <label className='d-block'>&nbsp;</label>
              <button className='formBtn'>Search</button>
            </div>
          </div>
        </div>
        <div className='button-part mb-4'>
          <ul>
            <li>
              <NavLink className='formBtn1' to="/admin/Add-company"><i className="fa-solid fa-plus"></i> Add Company</NavLink>
            </li>
            <li>
              <button className='formBtn1'><i className="fa-solid fa-file-excel"></i> Export </button>
            </li>
          </ul>
        </div>


        <div className='table-responsive'>
          <table className='custom-table'>
            <thead>
              <th>Company Name</th>
              <th>Name</th>
              <th>Title</th>
              <th>email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Login Url</th>
              <th>Action</th>
            </thead>
            <tbody>
              {currentCompanies && currentCompanies?.length > 0 ?
                currentCompanies && currentCompanies.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ whiteSpace: 'nowrap' }}>{item?.company_name}</td>
                      <td>{item?.first_name + ' ' + item?.last_name}</td>
                      <td>{item?.title_of_person_in_charge}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.status}</td>
                      <td style={{ position: 'relative' }}>
                        <NavLink to={`${BASE_URL}/#/${item?.slug}/login`}
                          target="_blank" >Link</NavLink>
                      </td>
                      <td>
                        <NavLink to={`/admin/edit-company/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                        {/* <button onClick={() => DeleteControl(item?.id)}><i className="fa-solid fa-trash"></i></button> */}
                      </td>
                    </tr>
                  )
                })

                :
                <h5>No data recorded</h5>
              }


            </tbody>

          </table>
        </div>

                {/* Pagination Controls */}
                <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button 
              key={index + 1} 
              onClick={() => handlePageChange(index + 1)} 
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>


      </div>
    </div>
  )
}

export default CompanyList