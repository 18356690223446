import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { GetTrainingRequest, DeleteTrainingRequest, GetEmployeeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';

const ViewTraining = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetTrainingResponse?.results
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [assigndate, setassigndate] = useState(null)
    const [assigndate1, setassigndate1] = useState(null)
    const [trainingmodule, settrainingmodule] = useState("")
    const [trainingmodule1, settrainingmodule1] = useState("")
    const [duedate, setduedate] = useState(null)
    const [datecompleted, setdatecompleted] = useState(null)
    const [duedate1, setduedate1] = useState(null)
    const [datecompleted1, setdatecompleted1] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown open/close
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const employeeList = fetchacompanyReducer?.GetEmployeeResponse?.results || []; // Access the employee list from the Redux state
    const locationReducer = useSelector((state) => state.LocationSlice)
    let locationid = localStorage.getItem("locationId");

    console.log(fetchacompanyReducer.GetTrainingResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(GetTrainingRequest())
    }, [])

    useEffect(() => {
        let obj = {
            "page_size": 10,
            'page': 1,
            'name': '',
            'status': '',
            'title': '',
            'employee_type': '',
            'employment_type': '',
            'location_id': locationid == null ? '' : locationid
        }
        dispatch(GetEmployeeRequest(obj))
    }, [locationReducer.locationList])

    useEffect(() => {
        dispatch(GetTrainingRequest())
    }, [fetchacompanyReducer?.DeleteTrainingResponse])

    const [searchQuery, setSearchQuery] = useState('');

    const filteredGroupList = groupList?.filter((item) => {
        return item?.training_name?.toLowerCase().includes(searchQuery?.toLowerCase());
    });

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteTrainingRequest(id))

                if (fetchacompanyReducer?.DeleteTrainingResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }


    const handleShowAssignModal = (training) => {
        // setSelectedTraining(training);
        setShowAssignModal(true);
    };

    const handleCloseAssignModal = () => {
        setShowAssignModal(false);
        // setSelectedTraining(null);
    };

    const handleTeacherSelect = (teacherId) => {
        if (selectedTeachers.includes(teacherId)) {
            setSelectedTeachers(selectedTeachers.filter(id => id !== teacherId)); // Remove if already selected
        } else {
            setSelectedTeachers([...selectedTeachers, teacherId]); // Add to selected
        }
    };


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Training Management</h3>
                </div>


                <div className='button-part mb-4' style={{ marginLeft: '745px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Training/AddTraining`}><i className="fa-solid fa-plus"></i> Add Training</NavLink>
                        </li>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='col-lg-3'>
                    <div className='form-group'>
                        <label>Training Name</label>
                        <input
                            className='form-control'
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                        />
                    </div>
                </div>

                {filteredGroupList && filteredGroupList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Training Name</th>
                                    <th>Training Duration</th>
                                    <th>Training Description</th>
                                    <th>Training Video Link</th>
                                    <th>Training Youtube Link</th>
                                    <th>Exam ID</th>
                                    <th>Exam Url</th>
                                    <th>Pass Mark</th>
                                    <th>Total Mark</th>
                                    <th style={{ textAlign: 'center' }}>Assign</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGroupList && filteredGroupList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.training_name}</td>
                                            <td>{item?.training_duration}</td>
                                            <td>{item?.training_description}</td>
                                            <td><a href={item?.training_video_link} target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href={item?.training_youtube_link} target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td>{item?.exam_id}</td>
                                            <td><a href={item?.exam_url} target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td>{item?.pass_mark}</td>
                                            <td>{item?.total_mark}</td>
                                            <td>
                                                <button style={{ fontSize: '12px', background: '#2497D4', border: 'none', color: '#fff', display: 'block', padding: '5px 5px', fontWeight: '500', width: '100px' }} onClick={() => handleShowAssignModal(item)}>Assign Now</button>
                                            </td>
                                            <td>
                                                <NavLink to={`/${userinfo?.company_slug}/Training/TrainingDetails/${item?.id}`} className='mr-2'><i className="fa-solid fa-eye"></i></NavLink>
                                                <NavLink to={`/${userinfo?.company_slug}/Training/EditTraining/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                <button onClick={() => DeleteHandle(item.id)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }

                <Modal show={showAssignModal} onHide={handleCloseAssignModal} size="lg">
                    <Modal.Header>
                        <Modal.Title>Assign Training</Modal.Title>
                        <button onClick={handleCloseAssignModal} className='btn btn-sm btn-warning'><i className="fa-solid fa-circle-xmark"></i></button>
                    </Modal.Header>




                    <Modal.Body>
                        <div className='row form-style mb-4'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>Select Teacher</label>
                                    <Dropdown className='custom-dropdown-checkbox' show={dropdownOpen} onToggle={() => setDropdownOpen(!dropdownOpen)}>
                                        <Dropdown.Toggle variant="success">
                                            {selectedTeachers.length > 0 ? `${selectedTeachers.length} selected` : 'Select Teacher'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ul>
                                                <li>
                                                    <input type="checkbox" name="allSelect"
                                                        onChange={(e) => handleTeacherSelect(e)}
                                                    />
                                                    <label>All Select</label>
                                                </li>
                                                {employeeList.map((employee, index) => {
                                                    return (
                                                        <li>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedTeachers.includes(employee.id)}
                                                                onChange={() => handleTeacherSelect(employee.id)}
                                                            />&nbsp; {employee.first_name + ' ' + employee.last_name}
                                                        </li>

                                                    )
                                                })}
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Date Assigned</label>
                                    <DatePicker className='form-control' selected={assigndate} onChange={(assigndate) => setassigndate(assigndate)}
                                        placeholderText="Assigned Date"
                                    />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Due Date</label>
                                    <DatePicker selected={duedate} onChange={(duedate) => setduedate(duedate)} className='form-control'
                                        placeholderText="Due Date"
                                    />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Date Completed</label>
                                    <DatePicker selected={datecompleted} onChange={(datecompleted) => setdatecompleted(datecompleted)} className='form-control'
                                        placeholderText="Date Completed"
                                    />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='formBtn' onClick={handleCloseAssignModal}>Assign</button>
                    </Modal.Footer>
                </Modal>

            </div>

        </div>
    )
}

export default ViewTraining