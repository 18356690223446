import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetLocationRequest, GetCountryRequest, GetStateRequest, GetTrainingListRequest, GetTitleRequest, GetDefaultlocationsRequest, GetManagerRequest, GetBackgroundRequest, GetcityRequest, GetstateSuccess, GetcitySuccess, UpdateEmployeeRequest, ViewEmployeeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from 'axios'


const EditEmployee = () => {

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var locationList = fetchacompanyReducer?.GetDefaultlocationsResponse?.results
    var countryList = fetchacompanyReducer?.GetcountryResponse
    var TitleList = fetchacompanyReducer?.GetTitleResponse?.results
    var managerList = fetchacompanyReducer?.GetManagerResponse?.results
    var backgroundList = fetchacompanyReducer?.GetBackgroundResponse?.results
    var TrainingList = fetchacompanyReducer?.GetTrainingResponse?.results
    const countryCodess = fetchacompanyReducer?.countryCodes || [];


    const [locationId, setlocationId] = useState("")
    const [salution, setsalution] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [phone, setphone] = useState("")
    const [mobile, setmobile] = useState("")
    const [countryCode, setcountryCode] = useState("")
    const [countryCodes, setcountryCodes] = useState([])
    const [email, setemail] = useState("")
    const [othermobile, setothermobile] = useState("")
    const [prospectstatus, setprospectstatus] = useState("")
    const [rejected, setrejected] = useState(false)
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [country, setcountry] = useState("")
    const [countryId, setcountryId] = useState("")
    const [stateList, setstateList] = useState([])
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [cityList, setcityList] = useState([])
    const [zipcode, setzipcode] = useState("")
    const [titlecp, settitlecp] = useState("")
    const [manager, setmanager] = useState("")
    const [dob, setdob] = useState("")
    const [description, setdescription] = useState("")
    const [backgroundcompany, setbackgroundcompany] = useState("")
    const [backgrounddate, setbackgrounddate] = useState("")
    const [hiredate, sethiredate] = useState("")
    const [enddate, setenddate] = useState("")
    const [iserror, setiserror] = useState("");

    const isEndDateInFuture = enddate && moment(enddate).isSameOrAfter(moment(), 'day');
    console.log('isEndDateInFuture',isEndDateInFuture)

    const [employmenttype, setemploymenttype] = useState("")
    const [isprinciple, setisprinciple] = useState(true)
    const [employeeType, setemployeeType] = useState(fetchacompanyReducer?.ViewEmployeeResponse?.results?.[0]?.employee_type)
    const { id } = useParams()
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    useEffect(() => {
        var editinfo = fetchacompanyReducer?.ViewEmployeeResponse?.results[0];
        if (editinfo) {
            const mobilePhone = editinfo?.mobile_phone;
            const dialCode = mobilePhone.slice(0, mobilePhone.indexOf(' ') + 1);
            const countryCode = countryCodes.find(code => code.code === dialCode);
            setemployeeType(editinfo?.employee_type);
            setsalution(editinfo?.salutation);
            setfname(editinfo?.first_name);
            setlname(editinfo?.last_name);
            setphone(editinfo?.phone);
            setmobile(editinfo?.mobile_phone);
            setemail(editinfo?.email);
            setothermobile(editinfo?.other_phone);
            setprospectstatus(editinfo?.status);
            setaddressone(editinfo?.street_address_1);
            setaddresstwo(editinfo?.street_address_2);
            setcountryId(editinfo?.country_id);
            setstate(editinfo?.state_id);
            setcity(editinfo?.city_id);
            setzipcode(editinfo?.zipcode);
            settitlecp(editinfo?.title);
            setmanager(editinfo?.manager);
            setdob(new Date(editinfo?.date_of_birth || new Date()));
            setdescription(editinfo?.description);
            setbackgroundcompany(editinfo?.backgound_check_company);
            setbackgrounddate(new Date(editinfo?.background_check_date || new Date()));
            sethiredate(new Date(editinfo?.hire_date || new Date()));
            setenddate(new Date(editinfo?.end_date || new Date()));
            const country = countryCodes.find(c => c.id === editinfo.country_id);
            if (country) {
                setcountry(country.name);
                setcountryCode(country.code);
            }
        }
    }, [fetchacompanyReducer?.ViewEmployeeResponse, countryCodes]);



    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(ViewEmployeeRequest(obj))
        dispatch(GetLocationRequest())
        dispatch(GetCountryRequest())
        dispatch(GetstateSuccess())
        dispatch(GetcitySuccess())
        dispatch(GetTitleRequest())
        dispatch(GetBackgroundRequest())
        dispatch(GetManagerRequest())
        dispatch(GetTrainingListRequest())
        dispatch(GetDefaultlocationsRequest(userinfo.user_id))
    }, [])



    console.log(fetchacompanyReducer.ViewEmployeeResponse)

    useEffect(() => {
        if (countryId) {
            dispatch(GetStateRequest({ id: countryId }));
        }
    }, [countryId, dispatch]);

    useEffect(() => {

        var editdata = fetchacompanyReducer?.ViewEmployeeResponse?.results[0]

        let obj = {
            id: editdata?.country_id
        }
        dispatch(GetStateRequest(obj))

        let obj1 = {
            id: editdata?.state_id
        }
        dispatch(GetcityRequest(obj1))


    }, [fetchacompanyReducer?.ViewEmployeeResponse])

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryIdMap = {
                    "Afghanistan": 1,
                    "Aland Islands": 2,
                    "Albania": 3,
                    "Algeria": 4,
                    "American Samoa": 5,
                    "Andorra": 6,
                    "Angola": 7,
                    "Anguilla": 8,
                    "Antarctica": 9,
                    "Antigua And Barbuda": 10,
                    "Argentina": 11,
                    "Armenia": 12,
                    "Aruba": 13,
                    "Australia": 14,
                    "Austria": 15,
                    "Azerbaijan": 16,
                    "Bahrain": 17,
                    "Bangladesh": 18,
                    "Barbados": 19,
                    "Belarus": 20,
                    "Belgium": 21,
                    "Belize": 22,
                    "Benin": 23,
                    "Bermuda": 24,
                    "Bhutan": 25,
                    "Bolivia": 26,
                    "Bonaire, Sint Eustatius and Saba": 27,
                    "Bosnia and Herzegovina": 28,
                    "Botswana": 29,
                    "Bouvet Island": 30,
                    "Brazil": 31,
                    "British Indian Ocean Territory": 32,
                    "Brunei": 33,
                    "Bulgaria": 34,
                    "Burkina Faso": 35,
                    "Burundi": 36,
                    "Cambodia": 37,
                    "Cameroon": 38,
                    "Canada": 39,
                    "Cape Verde": 40,
                    "Cayman Islands": 41,
                    "Central African Republic": 42,
                    "Chad": 43,
                    "Chile": 44,
                    "China": 45,
                    "Christmas Island": 46,
                    "Cocos (Keeling) Islands": 47,
                    "Colombia": 48,
                    "Comoros": 49,
                    "Congo": 50,
                    "Cook Islands": 51,
                    "Costa Rica": 52,
                    "Cote D'Ivoire (Ivory Coast)": 53,
                    "Croatia": 54,
                    "Cuba": 55,
                    "Curaçao": 56,
                    "Cyprus": 57,
                    "Czech Republic": 58,
                    "Democratic Republic of the Congo": 59,
                    "Denmark": 60,
                    "Djibouti": 61,
                    "Dominica": 62,
                    "Dominican Republic": 63,
                    "East Timor": 64,
                    "Ecuador": 65,
                    "Egypt": 66,
                    "El Salvador": 67,
                    "Equatorial Guinea": 68,
                    "Eritrea": 69,
                    "Estonia": 70,
                    "Falkland Islands": 71,
                    "Hondura": 72,
                    "Faroe Islands": 73,
                    "Fiji Islands": 74,
                    "Finland": 75,
                    "France": 76,
                    "French Guiana": 77,
                    "French Polynesia": 78,
                    "French Southern Territories": 79,
                    "Gabon": 80,
                    "Gambia The": 81,
                    "Georgia": 82,
                    "Germany": 83,
                    "Ghana": 84,
                    "Gibraltar": 85,
                    "Greece": 86,
                    "Greenland": 87,
                    "Grenada": 88,
                    "Guadeloupe": 89,
                    "Guam": 90,
                    "Guatemala": 91,
                    "Guernsey and Alderneyia": 92,
                    "Guinea": 93,
                    "Guinea-Bissau": 94,
                    "Guyana": 95,
                    "Haiti": 96,
                    "Heard Island and McDonald Islands": 97,
                    "Honduras": 98,
                    "Hong Kong S.A.R.": 99,
                    "Hungary": 100,
                    "Iceland": 101,
                    "India": 102,
                    "Indonesia": 103,
                    "Iran": 104,
                    "Iraq": 105,
                    "Ireland": 106,
                    "Israel": 107,
                    "Italy": 108,
                    "Jamaica": 109,
                    "Japan": 110,
                    "Jersey": 111,
                    "Jordan": 112,
                    "Kazakhstan": 113,
                    "Kenya": 114,
                    "Kiribati": 115,
                    "Kosovo": 116,
                    "Kuwait": 117,
                    "Kyrgyzstan": 118,
                    "Laos": 119,
                    "Latvia": 120,
                    "Lebanon": 121,
                    "Lesotho": 122,
                    "Liberia ": 123,
                    "Libya": 124,
                    "Liechtenstein": 125,
                    "Lithuania": 126,
                    "Luxembourg": 127,
                    "Macau S.A.R.": 128,
                    "Macedonia": 129,
                    "Madagascar": 130,
                    "Malawi": 131,
                    "Malaysia": 132,
                    "Maldives": 133,
                    "Mali": 134,
                    "Malta": 135,
                    "Man (Isle of)": 136,
                    "Marshall Islands": 137,
                    "Martinique": 138,
                    "Mauritania": 139,
                    "Mauritius": 140,
                    "Mayotte": 141,
                    "Mexico": 142,
                    "Micronesia": 143,
                    "Moldova": 144,
                    "Monaco": 145,
                    "Mongolia": 146,
                    "Montenegro": 147,
                    "Montserrat": 148,
                    "Morocco": 149,
                    "Mozambique": 150,
                    "Myanmar": 151,
                    "Namibia": 152,
                    "Nauru": 153,
                    "Nepal": 154,
                    "Netherlands": 155,
                    "New Caledonia": 156,
                    "New Zealand": 157,
                    "Nicaragua": 158,
                    "Niger": 159,
                    "Nigeria": 160,
                    "Niue": 161,
                    "Norfolk Island": 162,
                    "North Korea": 163,
                    "Northern Mariana Islands": 164,
                    "Norway": 165,
                    "Oman": 166,
                    "Pakistan": 167,
                    "Palau": 168,
                    "Palestinian Territory Occupied": 169,
                    "Panama": 170,
                    "Papua new Guinea": 171,
                    "Paraguay": 172,
                    "Peru": 173,
                    "Philippines": 174,
                    "Pitcairn Island": 175,
                    "Poland": 176,
                    "Portugal": 177,
                    "Puerto Rico": 178,
                    "Qatar": 179,
                    "Reunion": 180,
                    "Romania": 181,
                    "Russia": 182,
                    "Rwanda": 183,
                    "Saint Helena": 184,
                    "Saint Kitts And Nevis": 185,
                    "Saint Lucia": 186,
                    "Saint Pierre and Miquelon": 187,
                    "Saint Vincent And The Grenadines": 188,
                    "Saint-Barthelemy": 189,
                    "Saint-Martin (French part)": 190,
                    "Samoa": 191,
                    "San Marino": 192,
                    "Sao Tome and Principe": 193,
                    "Saudi Arabia": 194,
                    "Senegal": 195,
                    "Serbia": 196,
                    "Seychelles": 197,
                    "Sierra Leone": 198,
                    "Singapore": 199,
                    "Sint Maarten (Dutch part)": 200,
                    "Slovakia": 201,
                    "Slovenia": 202,
                    "Solomon Islands": 203,
                    "Somalia": 204,
                    "South Africa": 205,
                    "South Georgia": 206,
                    "South Korea": 207,
                    "South Sudan": 208,
                    "Spain": 209,
                    "Sri Lanka": 210,
                    "Sudan": 211,
                    "Suriname": 212,
                    "Svalbard And Jan Mayen Islands": 213,
                    "Swaziland": 214,
                    "Sweden": 215,
                    "Switzerland": 216,
                    "Syria": 217,
                    "Taiwan": 218,
                    "Tajikistan": 219,
                    "Tanzania": 220,
                    "Thailand": 221,
                    "The Bahamas": 222,
                    "Togo": 223,
                    "Tokelau": 224,
                    "Tonga": 225,
                    "Trinidad And Tobago": 226,
                    "Tunisia": 227,
                    "Turkey": 228,
                    "Turkmenistan": 229,
                    "Turks And Caicos Islands": 230,
                    "Tuvalu": 231,
                    "Uganda": 232,
                    "Ukraine": 233,
                    "United Arab Emirates": 234,
                    "United Kingdom": 235,
                    "United States": 236,
                    "United States Minor Outlying Islands": 237,
                    "Uruguay": 238,
                    "Uzbekistan": 239,
                    "Vanuatu": 240,
                    "Vatican City State (Holy See)": 241,
                    "Venezuela": 242,
                    "Vietnam": 243,
                    "Virgin Islands (British)": 244,
                    "Virgin Islands (US)": 245,
                    "Wallis And Futuna Islands": 246,
                    "Western Sahara": 247,
                    "Yemen": 248,
                    "Zambia": 249,
                    "Zimbabwe": 250
                };
                const codes = response.data.map(country => {
                    const predefinedCode = predefinedCountryCodes[country.name.common];
                   const code = predefinedCode || `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`;
                   return {
                       code,
                       name: country.name.common,
                       flag: country.flags.svg,
                       id: countryIdMap[country.name.common] || country.ccn3
                    };
               });
               console.log("Testcodes", codes);
               setcountryCodes(codes);
               const defaultCountry = codes.find(c => c.name === country); // Find the default country
            //    if (defaultCountry) {
            //        setcountry(defaultCountry.name);
            //        setcountryCode(defaultCountry.code);
            //    }
           } catch (e) {
               console.error(e);
           }
       };
       fetchCountryCodes();
   }, []);

// useEffect(() => {
//     const fetchCountryCodes = async () => {
//         try {
//             const response = await axios.get('https://restcountries.com/v3.1/all');
//             const codes = response.data.map(country => ({
//                 code: `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`,
//                 name: country.name.common,
//                 id: country.ccn3
//             }));
//             setcountryCodes(codes);
//         } catch (e) {
//             console.error(e);
//         }
//     };
//     fetchCountryCodes();
// }, [country]);

   const predefinedCountryCodes = {
       'United States': '+1',
       'Canada': '+1',
       // Add more predefined codes as needed
   };
   
    useEffect(() => {
        setstateList(fetchacompanyReducer?.GetstateResponse)
    }, [fetchacompanyReducer?.GetstateResponse])

    useEffect(() => {
        setcityList(fetchacompanyReducer?.GetcityResponse)
    }, [fetchacompanyReducer?.GetcityResponse])

    const CountryHandle = (e) => {
        const selectedCountry = e.target.value;
        const selectedCountryData = countryCodes.find(c => c.name === selectedCountry) || {};
        const selectedCountryCode = selectedCountryData.code || '';
        const selectedCountryId = selectedCountryData.id || '';
        let obj = {
            id: selectedCountryId // Use the id value from the countryCodes array
        };
        setcountryId(selectedCountryId);
        dispatch(GetStateRequest(obj));
        setcountry(selectedCountry);
        setcountryCode(selectedCountryCode); // Update countryCode state
    };

    const stateHandle = (e) => {
        setstate(e.target.value)
        setiserror('')
        let obj = {
            id: e.target.value
        }
        dispatch(GetcityRequest(obj))
    }



    const SubmitHandle = () => {

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (employeeType == '') {
            setiserror('employeeType');

            // } else if (locationId == '') {
            //     setiserror('locationId');
        } else if (fname == '') {
            setiserror('fname');

        } else if (lname == '') {
            setiserror('lname');
        } else if (mobile == '') {
            setiserror('mobile');
        } else if (reg.test(email) === false) {
            setiserror('email');
        } else if (dob == null) {
            setiserror('dob');
        } else if (titlecp == '') {
            setiserror('titlecp');
        } else if (country == '') {
            setiserror('country');
        } else if (state == '') {
            setiserror('state');
        } else if (city == '') {
            setiserror('city');
        } else if (addressone == '') {
            setiserror('addressone');
        } else if (zipcode == '') {
            setiserror('zipcode');
        } else if (prospectstatus === "inactive" && !enddate && employeeType !== "Applicant") {
            Swal.fire({
                icon: "error",
                text: "End date is required",
            });
        }

        else {





            //     var arr = []

            //     traininfo.forEach((element, index) =>{
            //         arr.push({
            //             "id":element?.id,
            //             "training_id": element.label,
            //             "start_date":  moment(element.assigndate).format("YYYY-MM-DD"),
            //             "due_date":   moment(element.duedate).format("YYYY-MM-DD"),
            //             "completion_date":  moment(element.completedate).format("YYYY-MM-DD"),
            //         })
            //     })

            // console.log(arr)

            let obj = {
                "employee_type": employeeType,
                "is_principle": isprinciple,
                // "location_id": locationId,
                "salutation": salution,
                "first_name": fname,
                "last_name": lname,
                "title": Number(titlecp),
                "manager": manager,
                "phone": phone,
                "email": email,
                "mobile_phone": countryCode + mobile,
                "other_phone": othermobile,
                "date_of_birth": moment(dob).format("YYYY-MM-DD"),
                "status": prospectstatus,
                "is_application_rejected": rejected == false ? 0 : 1,
                "employment_type": employmenttype,
                "backgound_check_company": backgroundcompany,
                "background_check_date": moment(backgrounddate).format("YYYY-MM-DD"),
                "hire_date": moment(hiredate).format("YYYY-MM-DD"),
                "end_date": enddate == null ? '' : moment(enddate).format("YYYY-MM-DD"),
                "street_address_1": addressone,
                "street_address_2": addresstwo,
                "country_id": Number(countryId),
                "state_id": Number(state),
                "city_id": Number(city),
                "zipcode": Number(zipcode),
                "description": description,


            }

            console.log(obj)


            dispatch(UpdateEmployeeRequest({ obj: obj, id: id, navigate: navigate }))


        }
    }
    const radioHandle = (e) => {
        setisprinciple(e.target.value === 'true' ? 1 : 0);
    }


    return (
        <div className='common-layout'>

            <Loader visible={fetchacompanyReducer.isloading} />


            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Edit Employee</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>
                    <div className='col-lg-12 mb-2'>
                        <h6>Employee information</h6>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Employee Type <span class="asterisk">*</span></label>
                            <select className={`form-control ${iserror == 'employeeType' ? 'error' : ''}`} value={employeeType}
                                onChange={(e) => { setemployeeType(e.target.value); setiserror('') }}>
                                <option value="">--Employee Type---</option>

                                <option value="Corporate Employee">Corporate Employee</option>
                                {userinfo?.user_type == "COMPANY" && <>
                                    <option value="Franchise Owned">Franchise Owned</option>
                                    <option value="Corporate Owned">Corporate Owned</option>
                                    <option value="Applicant">Applicant</option>
                                </>
                                }
                            </select>
                        </div>
                    </div>
                    {/* <div className='col-lg-4'>
               <div className='form-group'>
               <label className='selectlabel'>Select Location <span class="asterisk">*</span></label>
                  <select className={`form-control ${iserror == 'locationId'? 'error': ''}`}  value={locationId}
                   onChange={(e)=>{setlocationId(e.target.value);setiserror('')}}>
                    <option value="">--Location---</option>
                    {locationList&&locationList.map((item, i)=>{
                        return (
                            <option key={i} value={item?.id}>{item?.location_name}</option>
                        )
                    })}
                  </select>
               </div>
           </div> */}

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Status </label>
                            <select className='form-control' value={prospectstatus}
                                onChange={(e) => setprospectstatus(e.target.value)} disabled={!isEndDateInFuture}>
                                <option value="">--Status---</option>
                                <option value="active">Active</option>
                                <option value="inactive">In-active</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Title <span class="asterisk">*</span></label>
                            <select className={`form-control ${iserror == 'titlecp' ? 'error' : ''}`} value={titlecp}
                                onChange={(e) => { settitlecp(e.target.value); setiserror('') }}>
                                <option value="">--Select Title---</option>
                                {TitleList && TitleList.map((title, i) => {
                                    return (
                                        <option key={i} value={title?.id}>{title?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Salutation </label>
                            <select className='form-control' value={salution}
                                onChange={(e) => setsalution(e.target.value)}>
                                <option value="">--Salutation---</option>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>

                            </select>

                        </div>
                    </div>


                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter First Name <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control ${iserror == 'fname' ? 'error' : ''}`} placeholder='First Name'

                                value={fname}
                                onChange={(e) => { setfname(e.target.value); setiserror('') }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Last Name <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control ${iserror == 'employeeType' ? 'error' : ''}`} placeholder='Last Name'
                                value={lname}
                                onChange={(e) => { setlname(e.target.value); setiserror('') }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 1 <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control ${iserror == 'addressone' ? 'error' : ''}`} placeholder='Street Address 1'
                                value={addressone}
                                onChange={(e) => { setaddressone(e.target.value); setiserror('') }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 2 </label>
                            <input type='text' className='form-control' placeholder='Street Address 2'
                                value={addresstwo}
                                onChange={(e) => setaddresstwo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Country <span className="asterisk">*</span></label>
                            <select className={`form-control ${iserror === 'country' ? 'error' : ''}`} value={country} onChange={CountryHandle}>
                                <option value="">--Select country---</option>
                                {countryCodes
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((list, i) => (
            <option key={i} value={list.name}>{list.name}</option>
        ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select State <span class="asterisk">*</span></label>
                            <select className={`form-control ${iserror == 'state' ? 'error' : ''}`} value={state}
                                onChange={stateHandle}>
                                <option value="">--Select State---</option>

                                {stateList && stateList.map((list, i) => {
                                    return (
                                        <option key={i} value={list?.state_id
                                        }>{list?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select City <span class="asterisk">*</span></label>
                            <select className={`form-control ${iserror == 'city' ? 'error' : ''}`} value={city}
                                onChange={(e) => { setcity(e.target.value); setiserror('') }}>
                                <option value="">--Select city---</option>
                                {cityList && cityList.map((list, i) => {
                                    return (
                                        <option key={i} value={list?.city_id
                                        }>{list?.name}</option>
                                    )

                                })}

                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Zipcode <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control ${iserror == 'zipcode' ? 'error' : ''}`} placeholder='Zipcode'
                                value={zipcode}
                                onChange={(e) => { setzipcode(e.target.value); setiserror('') }}
                                maxLength="6"
                                minLength="6"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Mobile Number <span class="asterisk">*</span></label>
                            <div className='d-flex'>
                                <div className='col-lg-4'>
                                    <select
                                        className='form-control' style={{ width: '140%', marginLeft: '-15px', padding: '11px 0px', fontSize: '12px' }}
                                        value={countryCode} disabled
                                        onChange={(e) => setcountryCode(e.target.value)}
                                    >
                                    {countryCodes.map((country, index) => (
                                        <option key={index} value={country.code}>{country.name} ({country.code})</option>
                                    ))}
                                    </select>
                                </div>
                                <div className='col-lg-8'>
                                    <input type='text' className={`form-control ${iserror == 'mobile' ? 'error' : ''}`} style={{ width: '118%', marginLeft: '-15px' }} placeholder='Mobile Phone'
                                        value={mobile}
                                        onChange={(e) => { setmobile(e.target.value); setiserror('') }}
                                        maxLength="10"
                                        minLength="10"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'> Enter Phone Number </label>
                            <input type='text' className='form-control' placeholder='Phone'
                                value={phone}
                                onChange={(e) => setphone(e.target.value)}
                                maxLength="10"
                                minLength="10"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'> Enter Other Phone Number </label>
                            <input type='text' className='form-control' placeholder='Other Phone'
                                value={othermobile}
                                onChange={(e) => setothermobile(e.target.value)}
                                maxLength="10"
                                minLength="10"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Email <span class="asterisk">*</span></label>
                            <input type='email' className={`form-control ${iserror == 'email' ? 'error' : ''}`} placeholder='Email'
                                value={email}
                                onChange={(e) => { setemail(e.target.value); setiserror('') }}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Date of Birth <span class="asterisk">*</span></label>
                            <DatePicker selected={dob} onChange={(date) => { setdob(date); setiserror('') }} className={`form-control ${iserror == 'dob' ? 'error' : ''}`}
                                placeholderText="Select DOB"
                            />
                        </div>
                    </div>

                    {employeeType !== "Applicant" && (
                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Manager </label>
                                <select className='form-control' value={manager}
                                    onChange={(e) => setmanager(e.target.value)}>
                                    <option value="">--Select Manager---</option>
                                    {managerList && managerList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.id}>{list?.first_name + ' ' + list?.last_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    )}

                    {employeeType !== "Applicant" && (
                        <>
                            <div className='col-lg-12'>
                                <hr />
                            </div>
                            <div className='col-lg-12 mb-2'>
                                <h6>Employeement information</h6>
                            </div>
                            {/* <div className='col-lg-4'>
                                <div className='form-group'>
                                    <input type="checkbox" defaultChecked={rejected}
                                        onChange={() => setrejected(!rejected)} /> Application Rejected
                                </div>

                            </div> */}
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Background check company <span class="asterisk">*</span></label>
                                    <select className={`form-control ${iserror == 'backgroundcompany' ? 'error' : ''}`} value={backgroundcompany}
                                        onChange={(e) => { setbackgroundcompany(e.target.value); setiserror('') }}>
                                        <option value="">--Background check company (cp)---</option>
                                        {backgroundList && backgroundList.map((item, i) => {
                                            return (
                                                <option key={i} value={item?.id}>{item?.bcc_name}</option>
                                            )

                                        })}

                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Background Date <span class="asterisk">*</span></label>
                                    <DatePicker selected={backgrounddate} onChange={(date) => { setbackgrounddate(date); setiserror('') }} className={`form-control ${iserror == 'backgrounddate' ? 'error' : ''}`}
                                        placeholderText="Background check completed"
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Hire Date <span class="asterisk">*</span></label>
                                    <DatePicker selected={hiredate} onChange={(date) => { sethiredate(date); setiserror('') }} className={`form-control ${iserror == 'hiredate' ? 'error' : ''}`}
                                        placeholderText="Hire date"
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select End Date {prospectstatus === "inactive" && <span className="asterisk">*</span>}</label>
                                    <DatePicker selected={enddate} onChange={(date) => {
                                        setenddate(date);
                                        if (date) {
                                            setprospectstatus("inactive");
                                        }
                                    }} className='form-control'
                                        placeholderText="mm/dd/yyyy"
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Employment Type <span class="asterisk">*</span></label>
                                    <select className={`form-control ${iserror == 'employmenttype' ? 'error' : ''}`} value={employmenttype}
                                        onChange={(e) => { setemploymenttype(e.target.value); setiserror('') }}>
                                        <option value="">--Employment Type---</option>
                                        <option value="parmerent">Full Time</option>
                                        <option value="part time">Part Time</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                    {/* <div className='col-lg-12'>
            <hr />
          </div>
           <div className='col-lg-12 mb-2'>
            <h6>Training information <button className='btn btn-primary' onClick={AddHandle}>Add row</button></h6>
          </div>
                    
          <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-2'>
                    
                </div>
                <div className='col-lg-3'>
                    <label>Date Assigned</label>
                </div>    
                <div className='col-lg-3'>
                    <label>Date Due</label>
                </div> 
                <div className='col-lg-3'>
                    <label>Date Completed</label>
                </div> 
            </div>   
                    
                    {traininfo.map((item, index)=>{
                        return (
                            <div className='row' key={index}>
                                <div className='col-lg-2'>
                                    <div className='form-group'>
                                <select className='form-control'  value={item.label}
                                        onChange={(e)=>TrainingHandle(e.target.value, 'label', index, 'isdisabled')}>
                                            <option value="">--Training Module---</option>
                                            {TrainingList&&TrainingList.map((item, i)=>{
                                                return (
                                                    <option key={i} value={item?.id} >{item?.training_name}</option>
                                                )
                                                
                                            })}
                                    
                                        </select>
                                </div>
                                </div>
                                <div className='col-lg-3'>
                                <div className='form-group'>
                                    <DatePicker selected={item.assigndate} onChange={(date)=>dateHandle(date, 'assigndate',  index)} className='form-control'
                                    placeholderText="MM/DD/YYYY"
                                    />
                                    </div>
                                </div>    
                                <div className='col-lg-3'>
                                <div className='form-group'>
                                    <DatePicker selected={item.duedate} onChange={(date)=>dateHandle(date, 'duedate',  index)} className='form-control'
                                    placeholderText="MM/DD/YYYY"
                                    />
                                    </div>
    
                                </div> 
                                <div className='col-lg-3'>
                                    <div className='form-group'>
                                        <DatePicker selected={item.completedate} onChange={(date)=>dateHandle(date, 'completedate',  index)} className='form-control'
                                        placeholderText="MM/DD/YYYY"
                                        />
                                        </div>
                                </div> 
                                <div className='col-lg-1'>
                                    {index > 0 &&
                                    <div className='form-group'>
                                        <button className='btn brn-sm btn-danger' onClick={()=>DeleteHandle(index)}><i className="fa-solid fa-trash-can"></i></button>
                                        </div>
                    }
                                </div>
                            </div>
                        )
                    })}
    
          </div>       */}

                    <div className='col-lg-12'>
                        <hr />
                    </div>
                    {/* <div className='col-lg-12 mb-2'>
            <h6>Address information</h6>
          </div> */}

                    {/* <div className='col-lg-12'>
            <hr />
          </div> */}
                    <div className='col-lg-12 mb-2'>
                        <h6>Description information</h6>
                    </div>
                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Description</label>
                            <textarea className='form-control' placeholder='Write Description'
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            >

                            </textarea>
                        </div>
                    </div>


                </div>
                <button className='formBtn' onClick={SubmitHandle}>Update</button>
            </div>
        </div>
    )
}

export default EditEmployee