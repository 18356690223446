import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import ApiConnection from '../utils/ApiConnection'
import moment from "moment";


const StudentsList = () => {

  const [search, setsearch] = useState("")
  const [Namesearch, setNamesearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [show, setShow] = useState(false);
  const [StudentList, setStudentList] = useState([])

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  console.log('userinfo', userinfo.user_id)
  var studentList = fetchacompanyReducer?.GetStudentListResponse?.results

  console.log(fetchacompanyReducer.GetStudentListResponse)

  const dispatch = useDispatch()
  const { id } = useParams()


  const handleClose = () => setShow(false);


  const [traininfo, settraininfo] = useState([{
    label: '',
    assigndate: null,
    duedate: null,
    completedate: null,
    isdisabled: false
  }]);

  const column = [
    {
      "key": "First Name",
      "value": "First_Name",
      "ischecked": false
    },
    {
      "key": "Last Name",
      "value": "Last_Name",
      "ischecked": false
    },
    {
      "key": "Gender",
      "value": "gender",
      "ischecked": false
    },
    {
      "key": "Grade",
      "value": "grade_name",
      "ischecked": false
    },
    {
      "key": "School",
      "value": "SVM Public School",
      "ischecked": false
    },
    {
      "key": "School Year",
      "value": "school_year",
      "ischecked": false
    },
    {
      "key": "Teacher",
      "value": "Arnab",
      "ischecked": false
    },
    {
      "key": "Description",
      "value": "student_description",
      "ischecked": false
    },
    {
      "key": "Medical Information",
      "value": "medical_information",
      "ischecked": false
    },
    {
      "key": "School Subject Matter",
      "value": "school_subject_matter",
      "ischecked": false
    },
    {
      "key": "Student Notes",
      "value": "students_notes",
      "ischecked": false
    },
    {
      "key": "Notes From Center Director",
      "value": "notes_from_center_director",
      "ischecked": false
    },

  ]

  const columns = [
    {
      name: 'Student Name',
      selector: row => row.first_name + ' ' + row.last_name,
      sortable: true,
    },
    {
      name: 'Grade',
      selector: row => row.grade_name,
      sortable: true,
    },
    {
      name: 'School',
      selector: row => row.school_name,
      sortable: true,
    },
    {
      name: 'School Year',
      selector: row => row.school_year,
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row.virtual_loocation,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentDetails/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
        </>
      ),
    },
  ];

  const [columnName, setColumnName] = useState(column)

  useEffect(() => {
    const result = studentList && studentList.filter(data => {
      return data.first_name?.toLowerCase().match(Namesearch?.toLowerCase())
    })
    setFilterResult(result)
  }, [Namesearch])

  useEffect(() => {
    setFilterResult(studentList)
  }, [fetchacompanyReducer?.GetStudentListResponse])

  const GetStudent = async () => {
    try {
      // const response = await ApiConnection.get(`student-crud/?server_type=production&prospect_id=${userinfo.user_id}`)
      const response = await ApiConnection.get(`student-crud/?server_type=production&prospect_user_id=${userinfo?.user_id}&prospect_login=${1}`)
      if (response?.status == 200) {
        console.log('student_crud', response)
        setStudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  useEffect(() => {
    GetStudent()

  }, [])



  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> Student Management</h3>
        </div>

        {/* <div className='table-responsive custom-table'>
                    <DataTable
                        columns={columns}
                        data={FilterResult}
                        pagination
                        highlightOnHover
                    />
                </div> */}

        {StudentList && StudentList.length > 0 ?
          <div className='table-responsive'>
            <table className='custom-table'>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Grade</th>
                  <th>School</th>
                  <th>School Year</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {StudentList && StudentList.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item?.first_name} {item?.last_name}</td>
                      <td>{item?.grade_name}</td>
                      <td>{item?.school_name}</td>
                      <td>{item?.school_year}</td>
                      <td>{item?.virtual_loocation}</td>
                      <td><NavLink to={`/${userinfo?.company_slug}/application/guardian/studentDetails/${item?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink></td>
                    </tr>
                  )
                })}
              </tbody>

            </table>
          </div>
          :
          <h6>No data found</h6>
        }



      </div>
    </div>
  )
}

export default StudentsList